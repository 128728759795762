import { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  userMouseDownCoordinate,
  userMouseUpPosAction,
  userStartDragAction,
  userStopDragAction,
} from "../webhid/action";

function ImageUnit({ width, height, text, icon, uid, selected, onClickEvent }) {
  const newW = 2 * width;
  const newH = 2 * height;

  let common = useSelector((state) => state.persistent.common);
  const device = common.deviceName + "-Configure";
  common = common[device];
  const dispatch = useDispatch();

  // 鼠标按下
  const handleMouseDown = useCallback((e) => {
    dispatch(userMouseUpPosAction(-800, -800));
    console.log("handleMouseDown --> We begin to drag?", icon, "uid", uid);
    dispatch(userMouseDownCoordinate(e.clientX, e.clientY));
    dispatch(userStartDragAction(icon, uid));
  });

  return (
    <div
      onMouseDown={handleMouseDown}
      onMouseUp={(e) => {
        onClickEvent(true);
        dispatch(userStopDragAction());
      }}
      style={{
        width: `${newW}vh`,
        height: `${newW}vh`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "1vh",
        fontSize: "0.55rem",
      }}
    >
      <div
        style={{
          width: "100%",
          height: `${newH}vh`,
          borderRadius: "4px",
          display: "flex",

          flexDirection: "column",
          padding: "2px",
          border: selected ? "1px solid #0045d5" : "1px solid #e1e1e1",
        }}
        onClick={(e) => {
          onClickEvent(!selected);
        }}
      >
        <img
          src={icon}
          alt={text}
          title={text}
          draggable="false"
          onDragStart={(e) => e.preventDefault()}
          style={{ width: "100%", height: "100%" }}
        ></img>
        {/* <span>{text}</span> */}
      </div>
    </div>
  );
}

export default ImageUnit;
