import { useDispatch, useSelector } from "react-redux";
import CommonButton from "./CommonButton";
import {
  changeAppLanguage,
  clearAllPopupAction,
  settingSetRadioChoice,
} from "../webhid/action";
import SeparatorLine from "./SeparatorLine";
import "react-toastify/dist/ReactToastify.css";

import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { isAllOf } from "@reduxjs/toolkit";

function MoreLanguageUI({}) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  let common = useSelector((state) => state.persistent.common);

  let isLight = common.isLight;
  const languageArray = common.appConfig.languageArray;
  const device = common.deviceName + "-Configure";
  common = common[device];

  return (
    <div
      className="container.ui"
      style={{
        position: "absolute",
        width: "56%",
        height: "40%",
        top: "50%",
        padding: 0,
        left: "50%",
        backgroundColor: isLight ? "white" : "#2b2b2b",
        borderRadius: "8px",
        zIndex: 909,
        transform: "translate(-50%, -50%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        className="lang_upper_part"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          margin: "2vh 0vh 1vh 2vh",
          position: "relative",
          fontSize: "0.8rem",
        }}
      >
        <span style={{ fontWeight: "bold" }}>{t("more_language_setting")}</span>

        <div
          className="buttonGroup"
          style={{
            borderRadius: "5px",
            padding: "0.6vh 1.9vh",
            position: "absolute",
            right: 0,
          }}
        >
          <CommonButton
            text={t("dialog_btn_cancel")}
            actionCallback={() => {
              dispatch(clearAllPopupAction());
            }}
            blue={false}
          />

          <CommonButton
            text={t("dialog_btn_confirm")}
            actionCallback={() => {
              dispatch(clearAllPopupAction());
            }}
            blue={true}
          />
        </div>
      </div>

      <div
        className="main_parts"
        style={{
          display: "flex",
          flex: 1,
          height: "90%",
          width: "100%",
          fontSize: "0.75rem",
          boxSizing: "border-box",
          padding: "2vh",

          justifyContent: "center",
          alignItems: "center",
          margin: "0",
        }}
      >
        <div style={{ width: "40%", height: "100%" }}>
          <div
            className="disablegroup"
            style={{
              display: "flex",
              fontSize: "0.75rem",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {languageArray.map((item, index) => (
              <div
                style={{
                  width: "50%",
                  margin: "0",
                  padding: "0",
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "center",
                }}
                key={index}
                onClick={() => {
                  dispatch(changeAppLanguage(item[2]));
                  i18n.changeLanguage(item[2]);
                  i18next.changeLanguage(item[2]);

                  dispatch(settingSetRadioChoice("language", index));
                }}
              >
                <input
                  style={{ width: "15%" }}
                  checked={common.settings.language === index}
                  onChange={() => {}}
                  type="radio"
                ></input>

                <img
                  style={{
                    width: "15%",
                  }}
                  src={item[1]}
                ></img>

                <label style={{ margin: "1vh" }}>{item[0]}</label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MoreLanguageUI;
