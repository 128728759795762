import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  sendAlertHintsAction,
  userClickLayerAction,
  userClickMapPendingKeyAction,
  userMouseUpPosAction,
  userSetDragMappingAction,
  sendFastTriggerSettingsToHID,
  userClickBottomTabAction,
  useCombinedResultAction as userCombinedResultAction,
} from "../webhid/action";

import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import DKSIcon from "../icons/dks@1x.png";
import MTIcon from "../icons/mt@1x.png";
import SnappyTappyIcon from "../icons/socd@1x.png";
import TGLIcon from "../icons/tgl@1x.png";
import MPTIcon from "../icons/mpt@1x.png";
import ENDIcon from "../icons/end@1x.png";
import SendDataToHID from "../webhid/sendData";

const LayerButton = ({ selected, index, text, onClick }) => {
  const common = useSelector((state) => state.persistent.common);
  let isLight = common.isLight;
  return (
    <button
      style={{
        backgroundColor: selected ? "#0043dd" : isLight ? "white" : "#666",
        color: selected ? "#eee" : "black",
        borderRadius: "3px",
        border: "none",
        width: "100%",
        margin: "1vh 0",
        padding: "0.6vh 0",
        cursor: "pointer",
        lineHeight: "1.5",
      }}
      onClick={() => {
        onClick(index);
      }}
    >
      {text}
    </button>
  );
};

function DataLoadFailWarning(content, isLight) {
  toast.warning(content, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: isLight ? "light" : "dark",
  });
}

function KeyBoardMap({ singleSelect }) {
  const dispatch = useDispatch();

  const [t, i18n] = useTranslation();
  let common = useSelector((state) => state.persistent.common);

  const keyboardData = common.deviceParams.layoutMappingData;

  const layoutDataLength = keyboardData ? keyboardData.length : -1;

  let isLight = common.isLight;

  const layoutPicture = common.deviceParams.layoutPicture;
  const layoutPictureEmpty = common.deviceParams.layoutPicture_empty;
  const layoutX = common.deviceParams.layoutX;
  const layoutY = common.deviceParams.layoutY;

  const device = common.deviceName + "-Configure";

  common = common[device];
  const selectLayerIndex = common.buttonMap.layerIndex;
  const topMenuIndex = common.topMenuIndex;

  if (topMenuIndex === 1 || topMenuIndex === 2) {
    singleSelect = false;
  } else if (topMenuIndex === 0 || topMenuIndex === 3) {
    singleSelect = true;
  }
  const [selectOption, setSelectOption] = useState(-1);
  const keyValues = common.keyValues || [];
  console.log("keyValues", keyValues);

  const keyLogsCurrent = common.calibrationMap.keyLogs;

  console.log("keyLogsCurrent", keyLogsCurrent);
  function onLayerClick(layerIndex) {
    dispatch(userClickLayerAction(layerIndex)); //based 0
    setClickObj([]);
  }

  useEffect(() => {
    setClickObj([]);
  }, [topMenuIndex]);

  //Middle.
  let selectedKeyIds = common.mapPendingKey;

  function selectButtonClick(buttonIndex) {
    console.log("buttonIndex", buttonIndex);
    setSelectOption(buttonIndex);

    if (layoutDataLength < 0) {
      DataLoadFailWarning(t("data_load_uncomplete"), isLight);
      return;
    }

    let newSelectIds = [];
    if (buttonIndex === 0) {
      keyboardData.forEach((key) => {
        newSelectIds.push(key.id);
      });
    } else if (buttonIndex === 1) {
      keyboardData.forEach((key) => {
        if (!selectedKeyIds.some((item) => item === key.id)) {
          newSelectIds.push(key.id);
        }
      });
    } else if (buttonIndex === 2) {
    }

    dispatch(userClickMapPendingKeyAction(newSelectIds));
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the entire canvas
    drawConfiguredData(canvas, newSelectIds);
    drawCurrentSelectKey(canvas, newSelectIds, true); // Draw the keyboard with the updated keys
  }

  //Bottom.
  const keyboardBelowClickValue = common.keyboardBelowClickValue;
  const belowUpdateFlag = common.belowUpdateFlag;

  let currentLayerMappingData = getCurrentMappingData(topMenuIndex);
  console.log("currentLayerMappingData", currentLayerMappingData);

  function getCurrentMappingData(topMenuIndex) {
    if (topMenuIndex === 0) {
      return common.buttonMap.mappingData[common.buttonMap.layerIndex];
    } else if (topMenuIndex === 1) {
      return common.lightness.mappingData;
    } else if (topMenuIndex === 3) {
      return common.superkeys.mappingData;
    } else if (topMenuIndex === 2) {
      return common.keyValues;
    }
  }

  const canvasRef = useRef(null);

  const canvas = canvasRef.current;
  const [clickObj, setClickObj] = useState([]);

  //当拖动时，buttonMap.mouseUpX会变化，因此会处理映射的时机。
  useEffect(() => {
    if (common.mouseUpX > 0 && canvas && layoutDataLength > 0) {
      const rect = canvas.getBoundingClientRect();
      console.log("canvas rect is ", rect);
      keyboardData.map((item, _) => {
        const canvasX =
          (common.mouseUpX - rect.left) * (canvas.width / rect.width);
        const canvasY =
          (common.mouseUpY - rect.top) * (canvas.height / rect.height);

        if (
          item.x < canvasX &&
          canvasX < item.x + item.width &&
          item.y < canvasY &&
          canvasY < item.y + item.height
        ) {
          //如果我最终拖动的和原来选中的想要配置的不是一样键。
          //因为我在拖动的时候，开始拖的时候就已经给配置了，但实质生效的是后面的拖动，所以前面的要revert掉。

          //但是后面的也得映射上去。

          let newSelectedKeys = [];
          if (singleSelect) {
            newSelectedKeys = [item.id];
          } else {
            newSelectedKeys = Array.from(new Set([...selectedKeyIds, item.id]));
          }

          setClickObj(newSelectedKeys);

          console.log(
            "We select key =====>",
            common.mouseUpX,
            item.id,
            selectedKeyIds,
            newSelectedKeys
          );

          const ctx = canvas.getContext("2d");
          ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the entire canvas

          // drag
          drawConfiguredData(canvas, newSelectedKeys);
          drawCurrentSelectKey(canvas, newSelectedKeys, false); // Draw the keyboard with the updated keys

          //底层通信映射对象使用的key 是item.id.
          dispatch(
            userSetDragMappingAction(item.row * 100 + item.col, newSelectedKeys)
          );
        }
      });

      dispatch(userMouseUpPosAction(-800, -800));
    }
  }, [common.mouseUpX]);
  // on Drag up to a button.

  function drawRoundedRect(ctx, x, y, width, height, radius) {
    ctx.beginPath();
    ctx.moveTo(x + radius, y); // 从左上角的圆弧开始
    ctx.lineTo(x + width - radius, y); // 上边线
    ctx.stroke();
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius); // 右上角圆弧
    ctx.lineTo(x + width, y + height - radius); // 右边线
    ctx.stroke();
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height); // 右下角圆弧
    ctx.lineTo(x + radius, y + height); // 下边线
    ctx.stroke();
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius); // 左下角圆弧
    ctx.lineTo(x, y + radius); // 左边线
    ctx.stroke();
    ctx.quadraticCurveTo(x, y, x + radius, y); // 左上角圆弧
    ctx.closePath(); // 闭合路径
    ctx.fill(); // 填充颜色
  }

  function drawConfiguredData(canvas, excludeIdArrays) {
    // TODO disable the selection.
    if (topMenuIndex === 1 || topMenuIndex === 4) return;

    if (layoutDataLength < 0) {
      //DataLoadFailWarning(t("data_load_uncomplete"), isLight);
      return;
    }
    // draw configured data.
    if (canvas) {
      const ctx = canvas.getContext("2d");
      //console.log("We configured data", currentLayerMappingData);
      for (let key in currentLayerMappingData) {
        //console.log("We configured key", key);

        const singleKeys = key.split(",");

        for (const single of singleKeys) {
          let modifiedSingle = single;
          if (single === "<") modifiedSingle = "<,";
          if (single.length === 0) continue;

          const item = keyboardData.find((k) => k.id === modifiedSingle);

          if (item) {
            let shouldDraw = true;
            //console.log("excludeIdArrays", excludeIdArrays);
            if (excludeIdArrays.some((id) => id === item.id))
              shouldDraw = false;

            if (shouldDraw) {
              if (topMenuIndex === 3) {
                ctx.fillStyle = "rgba(0, 0, 255, 1.0)"; // Set fill color for the rectangle
                ctx.strokeStyle = "blue";
                drawRoundedRect(
                  ctx,
                  item.x,
                  item.y,
                  item.width,
                  item.height,
                  12
                );
                // 在 superkeys 页面绘制对应类型的图标
                const size = 80;
                const x = item.x + item.width - size - 20;
                const y = item.y + 20;

                const type = currentLayerMappingData[key].type;
                console.log("SuperKeys type:", type);

                switch (type) {
                  case "mt":
                    if (!canvas.mtImagePromise) {
                      canvas.mtImagePromise = new Promise((resolve) => {
                        const image = new Image();
                        image.src = MTIcon;
                        image.onload = () => resolve(image);
                      });
                    }

                    canvas.mtImagePromise.then((image) => {
                      ctx.drawImage(image, x, y, size, size);
                    });
                    break;
                  case "tgl":
                    if (!canvas.tglImagePromise) {
                      canvas.tglImagePromise = new Promise((resolve) => {
                        const image = new Image();
                        image.src = TGLIcon;
                        image.onload = () => resolve(image);
                      });
                    }

                    canvas.tglImagePromise.then((image) => {
                      ctx.drawImage(image, x, y, size, size);
                    });
                    break;
                  case "end":
                    if (!canvas.endImagePromise) {
                      canvas.endImagePromise = new Promise((resolve) => {
                        const image = new Image();
                        image.src = ENDIcon;
                        image.onload = () => resolve(image);
                      });
                    }

                    canvas.endImagePromise.then((image) => {
                      ctx.drawImage(image, x, y, size, size);
                    });
                    break;
                  case "mpt":
                    if (!canvas.mptImagePromise) {
                      canvas.mptImagePromise = new Promise((resolve) => {
                        const image = new Image();
                        image.src = MPTIcon;
                        image.onload = () => resolve(image);
                      });
                    }

                    canvas.mptImagePromise.then((image) => {
                      ctx.drawImage(image, x, y, size, size);
                    });
                    break;
                  case "dsk":
                    if (!canvas.dksImagePromise) {
                      canvas.dksImagePromise = new Promise((resolve) => {
                        const image = new Image();
                        image.src = DKSIcon;
                        image.onload = () => resolve(image);
                      });
                    }

                    canvas.dksImagePromise.then((image) => {
                      ctx.drawImage(image, x, y, size, size);
                    });
                    break;
                  case "socd":
                    if (!canvas.socdImagePromise) {
                      canvas.socdImagePromise = new Promise((resolve) => {
                        const image = new Image();
                        image.src = SnappyTappyIcon;
                        image.onload = () => resolve(image);
                      });
                    }

                    canvas.socdImagePromise.then((image) => {
                      ctx.drawImage(image, x, y, size, size);
                    });
                    break;
                  default:
                    break;
                }
              } else if (topMenuIndex === 2 && keyLogsCurrent.length > 0) {
                ctx.fillStyle = "rgba(0, 0, 255, 0.5)";
                ctx.strokeStyle = "blue";
                drawRoundedRect(
                  ctx,
                  item.x,
                  item.y,
                  item.width,
                  item.height,
                  12
                );
              } else {
                ctx.fillStyle = "rgba(37, 37, 37, 1)"; // Set fill color for the rectangle
                ctx.strokeStyle = "rgba(37, 37, 37, 1)";
                drawRoundedRect(
                  ctx,
                  item.x + 10,
                  item.y + 10,
                  item.width - 20,
                  item.height - 20,
                  0
                );

                // 其他页面绘制文本
                let showText = currentLayerMappingData[key].showText;

                if (showText.charAt(0) === "/") {
                  // Create a new image promise if it doesn't exist
                  if (!canvas[showText]) {
                    canvas[showText] = new Promise((resolve) => {
                      const image = new Image();
                      image.src = showText; // Use the showText as the image path
                      image.onload = () => resolve(image);
                    });
                  }

                  // Draw the image when it's loaded
                  canvas[showText].then((image) => {
                    ctx.drawImage(
                      image,
                      item.x + 10,
                      item.y + 10,
                      item.width - 20,
                      item.height - 20
                    );
                  });
                } else {
                  showText = showText.split("\\n").join(" ");
                  ctx.font = "2.7rem bold Black";
                  ctx.textAlign = "center";
                  ctx.textBaseline = "middle";
                  ctx.fillStyle = "#AFB5BE";
                  ctx.fillText(
                    showText,
                    item.x + item.width / 2,
                    item.y + item.height / 2
                  );
                }
              }
            }
          }
        }
      }
    }
  }

  function drawCurrentSelectKey(canvas, currentSelection, isClick) {
    console.log("drawCurrentSelectKey");
    // TODO disable the selection.
    if (topMenuIndex === 1 || topMenuIndex === 4) return;

    if (layoutDataLength < 0) {
      //DataLoadFailWarning(t("data_load_uncomplete"), isLight);
      return;
    }

    console.warn(
      "We begin to drawCurrentSelectKey here isClick",
      isClick,
      "currentSelection",
      currentSelection
    );

    const ctx = canvas.getContext("2d");

    for (const keybutton of currentSelection) {
      const key = keyboardData.find((k) => k.id === keybutton);
      if (keyLogsCurrent && keyLogsCurrent.length > 0) {
        ctx.fillStyle = "rgba(71, 222, 202, 0.4)";
        ctx.strokeStyle = "#47deca";
        ctx.lineWidth = 5;
      } else {
        ctx.fillStyle = "rgba(21, 61, 223, 0.5)"; // Convert hex to decimal RGB values
        ctx.strokeStyle = "blue";
      }

      if (key) {
        console.log(
          "We begin to drawKeyboard red selected rectangle",
          keybutton
        );
        drawRoundedRect(ctx, key.x, key.y, key.width, key.height, 12);
        // draw text.
        if (!isClick) {
          // draw current mapping data.
          ctx.font = "2rem bold Arial"; // Set font size and family
          ctx.textAlign = "center"; // Center the text
          ctx.textBaseline = "middle"; // Middle align the text vertically
          ctx.fillStyle = "red"; // Set text color
          ctx.fillText(
            keyboardBelowClickValue.split("\\n").join(" "),
            key.x + key.width / 2,
            key.y + key.height / 2
          );
        } else {
          if (topMenuIndex === 0) {
            ctx.font = "2rem bold Arial"; // Set font size and family
            ctx.textAlign = "center"; // Center the text
            ctx.textBaseline = "middle"; // Middle align the text vertically
            ctx.fillStyle = "black"; // Set text color
            const text = t("mapping_press_to_bind"); // The text you want to measure
            const textMetrics = ctx.measureText(text); // Measure the text
            const textWidth = textMetrics.width; // Get the width of the text
            const textHeight = textMetrics.height;

            ctx.fillStyle = "rgba(239, 230, 230, 0.89)"; // 半透明绿色
            ctx.strokeStyle = "white";
            let x = key.x + key.width / 2 - (textWidth + 20) / 2;
            let y = key.y - key.height / 2 - 20;
            let width = textWidth + 20;
            let height = key.height / 2;
            drawRoundedRect(ctx, x, y, width, height, 12);

            ctx.font = "2rem bold Arial"; // Set font size and family
            ctx.textAlign = "center"; // Center the text
            ctx.textBaseline = "middle"; // Middle align the text vertically
            ctx.fillStyle = "black"; // Set text color
            ctx.fillText(text, x + width / 2, y + height / 2); // Center the text
          } else if (topMenuIndex === 3) {
            let x = key.x + key.width;
            let y = key.y;
            let size = 50;

            ctx.fillStyle = "rgba(215, 0, 0, 0.5)";
            drawCircle(ctx, x, y, 25);

            // Store the image loading promise in a variable that persists outside this function
            if (!canvas.selectImagePromise) {
              canvas.selectImagePromise = new Promise((resolve) => {
                const image = new Image();
                image.src = "/select.png";
                image.onload = () => resolve(image);
              });
            }

            // Use the cached promise to draw the image
            canvas.selectImagePromise.then((image) => {
              ctx.drawImage(image, x - size / 2, y - size / 2, size, size);
            });
          } else if (topMenuIndex === 2) {
            const keyData = keyValues.find((k) => k.id === keybutton);
            // console.log("keyData", keyData);
            if (keyData) {
              // 绘制行程值（左下角）
              ctx.font = "1.7rem bold Arial";
              ctx.textAlign = "left";
              ctx.textBaseline = "bottom";
              ctx.fillStyle = "#8a4e54";
              const travelText = `${keyData.travelValue.toFixed(2)}`;
              const travelX = key.x + 5;
              const travelY = key.y + key.height - 5;
              ctx.fillText(travelText, travelX, travelY);

              if (keyData.isRTEnabled && !keyData.isIndependentSensitivity) {
                // 绘制统一灵敏度值（右上角和右下角）
                ctx.font = "1.7rem bold Arial";
                ctx.textAlign = "right";

                // 右上角灵敏度值
                ctx.textBaseline = "top";
                ctx.fillStyle = "#63628e";
                const topRightX = key.x + key.width - 5;
                const topRightY = key.y + 5;
                ctx.fillText(
                  `${keyData.sensitivity.toFixed(2)}`,
                  topRightX,
                  topRightY
                );

                // 右下角灵敏度值
                ctx.textBaseline = "bottom";
                ctx.fillStyle = "#41645b";
                const bottomRightX = key.x + key.width - 5;
                const bottomRightY = key.y + key.height - 5;
                ctx.fillText(
                  `${keyData.sensitivity.toFixed(2)}`,
                  bottomRightX,
                  bottomRightY
                );
              } else if (
                keyData.isRTEnabled &&
                keyData.isIndependentSensitivity
              ) {
                // 绘制独立灵敏度值（右上角触发灵敏度，右下角重置灵敏度）
                ctx.font = "1.7rem bold Arial";
                ctx.textAlign = "right";

                // 右上角触发灵敏度
                ctx.textBaseline = "top";
                ctx.fillStyle = "#63628e";
                const topRightX = key.x + key.width - 5;
                const topRightY = key.y + 5;
                ctx.fillText(
                  `${keyData.triggerSensitivity.toFixed(2)}`,
                  topRightX,
                  topRightY
                );

                // 右下角重置灵敏度
                ctx.textBaseline = "bottom";
                ctx.fillStyle = "#41645b";
                const bottomRightX = key.x + key.width - 5;
                const bottomRightY = key.y + key.height - 5;
                ctx.fillText(
                  `${keyData.resetSensitivity.toFixed(2)}`,
                  bottomRightX,
                  bottomRightY
                );
              }
            }
          }
        }
      }
    }
  }

  function drawCircle(ctx, x, y, radius) {
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, Math.PI * 2); // Draw a circle

    ctx.fill(); // Fill the circle
    ctx.stroke(); // Outline the circle
  }

  // used to convert location in browser and canvas.
  const setCanvasSizeAttribute = () => {
    const canvas = canvasRef.current;
    canvas.width = layoutX;
    canvas.height = layoutY;
  };

  function canvasClick(event) {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const mouseX = event.clientX;
    const mouseY = event.clientY;

    const canvasX = (mouseX - rect.left) * (canvas.width / rect.width);
    const canvasY = (mouseY - rect.top) * (canvas.height / rect.height);

    console.log(
      "canvasClick MouseX ",
      canvasX,
      " MouseY ",
      canvasY,
      "SelectedKeyIds",
      selectedKeyIds
    );

    let validClick = false;

    if (layoutDataLength < 0) {
      DataLoadFailWarning(t("data_load_uncomplete"), isLight);
      return;
    }

    keyboardData.forEach((key) => {
      if (
        canvasX >= key.x &&
        canvasX <= key.x + key.width &&
        canvasY >= key.y &&
        canvasY <= key.y + key.height
      ) {
        validClick = true;
        let newSelectIds = [];
        // 如果已选中则取消选中，未选中则添加
        if (selectedKeyIds.some((k) => k === key.id)) {
          if (singleSelect) {
            newSelectIds = [];
          } else {
            newSelectIds = selectedKeyIds.filter((k) => k !== key.id);
          }
        } else {
          if (singleSelect) {
            newSelectIds = [key.id];
          } else {
            newSelectIds = [...selectedKeyIds, key.id];
          }
        }

        dispatch(userClickMapPendingKeyAction(newSelectIds));
        console.log("Throught click we set pending key is", newSelectIds);
        // ['I', 'O'] multiple select.
        setClickObj(newSelectIds);

        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the entire canvas

        // ROCK
        if (topMenuIndex === 0) {
          console.log(
            "Clicked node mapped value",
            currentLayerMappingData[key.id]
          );

          let mappedValue = currentLayerMappingData[key.id];

          if (mappedValue && mappedValue.combine) {
            dispatch(userClickBottomTabAction(3));
          }
          // dispatch(userClickBottomTabAction(3));
          dispatch(
            userCombinedResultAction(mappedValue ? mappedValue.combine : null)
          );
          console.log("ROCK1 Send", mappedValue ? mappedValue.combine : null);
        }

        // click
        drawConfiguredData(canvas, newSelectIds);
        drawCurrentSelectKey(canvas, newSelectIds, true);
      }
    });
    if (!validClick) {
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the entire canvas
      drawConfiguredData(canvas, []);

      //click empty place to cancel the selection.
      //dispatch(userClickMapPendingKeyAction([]));

      dispatch(userCombinedResultAction(null));
      setClickObj([]);
    }
  }

  useEffect(() => {
    setCanvasSizeAttribute();
    const canvas = canvasRef.current; // Get the canvas reference here
    // we have loaded the canvas and the layoutData.
    if (canvas) {
      console.log("We begin draw map data.");
      drawConfiguredData(canvas, []);
      setClickObj([]);
    }
    //clear Pending data
  }, [
    canvasRef,
    keyboardData,
    common.deviceName,
    selectLayerIndex,
    topMenuIndex,
  ]); //currentLayerMappingData

  useEffect(() => {
    if (
      canvas &&
      clickObj &&
      keyboardBelowClickValue &&
      keyboardBelowClickValue.length > 0
    ) {
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the entire canvas
      //clickObj用来在下面不断的切换映射的值的时候，能够更新如下的最新的点击的值。
      drawConfiguredData(canvas, clickObj);
      drawCurrentSelectKey(canvas, clickObj, false);

      ctx.font = "2rem bold Arial"; // Set font size and family
      ctx.textAlign = "center"; // Center the text
      ctx.textBaseline = "middle"; // Middle align the text vertically
      ctx.fillStyle = "blue"; // Set text color

      ctx.fillText(
        keyboardBelowClickValue.split("\\n").join(" "),
        clickObj.x + clickObj.width / 2,
        clickObj.y + clickObj.height / 2
      );
    }
  }, [keyboardBelowClickValue, belowUpdateFlag]);

  useEffect(() => {
    if (canvas && clickObj && topMenuIndex === 2) {
      const lastClickedKey = clickObj[clickObj.length - 1];
      if (!lastClickedKey) return;

      const key = keyboardData.find((k) => k.id === lastClickedKey);
      if (key) {
        const row = key.row;
        const col = key.col;

        const command = [
          "34",
          "03",
          "01",
          "00",
          row.toString(16).padStart(2, "0"),
          col.toString(16).padStart(2, "0"),
        ].join(" ");

        SendDataToHID(command);
      }

      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      drawConfiguredData(canvas, selectedKeyIds);
      drawCurrentSelectKey(canvas, selectedKeyIds, true);
    }
  }, [clickObj]);

  useEffect(() => {
    if (canvas && keyLogsCurrent.length > 0 && topMenuIndex === 2) {
      const keyLogsSet = new Set(keyLogsCurrent);
      let newSelectedKeys = keyboardData
        .filter((key) => keyLogsSet.has(key.id))
        .map((key) => key.id);

      console.log("newSelectedKeys", newSelectedKeys);

      // 只在 newSelectedKeys 与当前 selectedKeyIds 不同时更新
      if (!arraysEqual(newSelectedKeys, selectedKeyIds)) {
        dispatch(userClickMapPendingKeyAction(newSelectedKeys));
        setClickObj(newSelectedKeys);
      }

      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      drawConfiguredData(canvas, newSelectedKeys);
      drawCurrentSelectKey(canvas, newSelectedKeys, true);
    }
  }, [keyLogsCurrent, topMenuIndex]); // 添加 topMenuIndex 作为依赖项

  // 添加一个辅助函数来比较数组
  function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  // Add new useEffect to handle mapPendingKey changes
  useEffect(() => {
    if (canvas) {
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the entire canvas

      // Redraw configured data without any selected keys
      drawConfiguredData(canvas, []);

      // Only draw selected keys if there are any
      if (selectedKeyIds && selectedKeyIds.length > 0) {
        drawCurrentSelectKey(canvas, selectedKeyIds, true);
      }
    }
  }, [selectedKeyIds, canvas]);

  const layerNames = [t("map_mainlayer"), "FN1", "FN2(MAC)", "FN3"];

  const selectNames = [
    t("map_selectall"),
    t("map_reverse_select"),
    t("map_select_none"),
  ];

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "40vh",
        justifyContent: "center",
        marginTop: "3vh",
      }}
    >
      <div
        className="layers"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginRight: "2vh",
          width: "8%",
          visibility: topMenuIndex === 0 ? "visible" : "hidden",
        }}
      >
        {layerNames.map((item, index) => (
          <LayerButton
            key={index}
            index={index}
            selected={selectLayerIndex === index}
            onClick={onLayerClick}
            text={item}
          ></LayerButton>
        ))}
      </div>

      <div
        className="keyboard_image"
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "70%",
          height: "100%",
        }}
      >
        <canvas
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          draggable={false}
          onClick={canvasClick}
          ref={canvasRef}
        ></canvas>
        <img
          src={selectLayerIndex % 2 === 0 ? layoutPicture : layoutPictureEmpty}
          draggable={false}
          style={{ width: "100%", height: "100%" }}
        ></img>
      </div>

      <div
        className="select_menu"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "12%",
          alignItems: "center",
          justifyContent:
            topMenuIndex === 1 || topMenuIndex === 2 ? "center" : "flex-end",
          marginLeft: "2vh",
        }}
      >
        {(topMenuIndex === 0 || topMenuIndex === 3) && (
          <button
            style={{
              border: "none",
              width: "100%",
              borderRadius: "5px",

              backgroundColor: isLight ? "white" : "#666",
              margin: "0.3vh 0.8vh 0.3vh 3vh",
              padding: "0.9vh 0.9vh",
              cursor: "pointer",
            }}
            onClick={() => {
              setClickObj([]);
              // clear all the data.
              dispatch(
                sendAlertHintsAction(t("dialog_clear_all"), "clear_conf")
              );
            }}
          >
            {t("map_restore_all")}
          </button>
        )}

        {topMenuIndex === 2 &&
          selectNames.map((item, index) => (
            <SelectButton
              icon="/check-mark.png"
              text={item}
              selected={selectOption === index}
              index={index}
              key={index}
              onClick={selectButtonClick}
            />
          ))}
      </div>
    </div>
  );
}

export default KeyBoardMap;

const SelectButton = ({ selected, index, text, onClick, icon }) => {
  const common = useSelector((state) => state.persistent.common);
  let isLight = common.isLight;
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        //backgroundColor: selected ? "gray" : "white",
        // backgroundColor: selected ? "#0043dd" : isLight ? "white" : "#666",
        backgroundColor: isLight ? "white" : "#666",
        color: "black",
        alignItems: "center",
        margin: "1vh 0",
        padding: "0.1vh 0.3vh",
        borderRadius: "3px",
        cursor: "pointer",
      }}
      onClick={() => {
        console.log("select button click", index);
        onClick(index);
      }}
    >
      <img
        src={icon}
        style={{ width: "2vh", height: "2vh", margin: "0 1vh" }}
      ></img>
      <div
        style={{
          border: "none",
          width: "100%",
          padding: "0.4vh 0",
          lineHeight: "1.5",
        }}
      >
        {text}
      </div>
    </div>
  );
};
