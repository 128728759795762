import "../i18n.js";
import React, { useState, useEffect, useCallback } from "react";
import "../App.css";

import { useDispatch, useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

import TopMenuBar from "../components/TopMenuBar.js";
import { Outlet, useNavigate } from "react-router-dom";
import KeyBoardMap from "../components/KeyBoardMap.js";
import {
  clearAllPopupAction,
  clearButtonMapConfigures,
  popupConfigureUIAction,
  userMouseDownCoordinate,
  userMouseUpPosAction,
  userStopDragAction,
  userUpdateLayoutData,
} from "../webhid/action.js";

import MacroConfigureUI from "../components/MacroConfigureUI.js";
import MoreLanguageUI from "../components/MoreLanguageUI.js";
import AlerDialog from "../components/AlertDialog.js";

function MainStructure() {
  const { t } = useTranslation();
  const connect = useSelector((state) => state.connect);

  let common = useSelector((state) => state.persistent.common);
  const isLight = common.isLight;
  const layoutDataUrl = common.deviceParams.layout;

  const device = common.deviceName + "-Configure";

  let currentDevice = common.deviceName;
  console.log("We get device product name", common.deviceName);
  console.log('version0.2')
  common = common[device];

  const navigate = useNavigate();

  const selectOptions = [true, false, false, false, true];

  useEffect(() => {
    if (currentDevice == null || !common) {
      navigate("/");
    }
  }, [common, connect, currentDevice]);

  if (!common) {
    console.log("We jump to /");
    navigate("/");
  }

  const dispatch = useDispatch();

  const [dragPosition, setDragPosition] = useState({ x: 0, y: 0 }); // 虚拟控件的位置

  // 鼠标移动
  const handleMouseMove = useCallback((e) => {
    if (common.dragFlag) {
      setDragPosition({ x: e.clientX, y: e.clientY });
    }
  });

  useEffect(() => {
    console.log("ROCK we send the json request data url", layoutDataUrl);
    fetch(layoutDataUrl) // 替换为你的 JSON 文件 URL
      .then((response) => response.json())
      .then((json) => {
        // setLayoutData(json)
        console.log("ROCK we received the json data");
        dispatch(userUpdateLayoutData(json));
      })
      .catch((error) => console.error("Error ROCK fetching JSON:", error));
  }, []);

  // 鼠标松开
  const handleMouseUp = useCallback((e) => {
    if (common.dragFlag) {
      setDragPosition({ x: -800, y: -800 });
      dispatch(userStopDragAction());
      //update mouseUpX and mouseUpY, will cause the
      dispatch(userMouseUpPosAction(e.clientX, e.clientY));
    }

    dispatch(userMouseDownCoordinate(-1, -1));
  });

  let macroHintsButtons = [
    [
      t("setting"),
      "#276bF9",
      () => {
        dispatch(popupConfigureUIAction(true, common.macroMap.selectIndex));
      },
    ],
  ];

  let clearButtonmapButtons = [
    [
      t("dialog_btn_cancel"),
      isLight ? "#D9D9D9" : "#4d4d4d",
      () => {
        dispatch(clearAllPopupAction());
      },
    ],
    [
      t("dialog_btn_confirm"),
      "#276bF9",
      () => {
        dispatch(clearAllPopupAction());
        dispatch(clearButtonMapConfigures());
      },
    ],
  ];

  if (common) {
    return (
      <div
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        className="main-content"
        style={{
          whiteSpace: "nowrap",
          display: "flex",
          flexDirection: "column",
          padding: "3vh 4vh",
          width: "100%",
          margin: 0,
          flex: 1,
          justifyContent: "flex-start",
          backgroundColor: isLight ? "#f2f4f5" : "#171719",
          color: isLight ? "black" : "white",
        }}
      >
        {common.dragFlag && (
          <div
            style={{
              position: "absolute",
              width: "3.5vh",
              height: "3.5vh",
              top: dragPosition.y,
              left: dragPosition.x,
              backgroundColor: "blue",
              borderRadius: "50%",
              zIndex: 999,
              transform: "translate(-50%, -50%)",
            }}
          ></div>
        )}

        {common.mask && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              backgroundColor: "#00000090",
              borderRadius: "2px",
              zIndex: 809,
            }}
          ></div>
        )}

        {common.showConfigureUI && common.topMenuIndex === 0 && (
          <MacroConfigureUI />
        )}
        {common.showConfigureUI && common.topMenuIndex === 4 && (
          <MoreLanguageUI />
        )}

        {common.hintText.length > 0 && (
          <AlerDialog
            title={t("dialog_alert")}
            content={common.hintText}
            buttons={
              common.hintSource === "clear_conf"
                ? clearButtonmapButtons
                : macroHintsButtons
            }
          />
        )}

        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          //draggable
          pauseOnHover
          theme="light"
        />

        <TopMenuBar />
        <KeyBoardMap singleSelect={selectOptions[common.topMenuIndex]} />
        <Outlet />
      </div>
    );
  } else {
    return <div backgroundColor="black"></div>;
  }
}

export default MainStructure;
