import {
  CONNECTED_DEVICE,
  SET_FATAL_LOG,
  CHANGE_APP_LANG,
  convertIntToHexString,
  convertIntToHexStringKeep,
} from "./constants";
import SendDataToHID, { updateGlobalState } from "./sendData";
import { gameDisable } from "../SettingData";
import { getUidByKeyCode, getUidByText } from "./KeyboardKeyData";
import { getMiddleKeysData } from "../webhid/DeviceOrdinateData";

// console.log = function () {};
// console.warn = function () {};

export function getInitialLanguage() {
  const userLanguage =
    navigator.language ||
    navigator.browserLanguage ||
    navigator.systemLanguage ||
    navigator.userLanguage ||
    "";

  if (userLanguage.length === 0) {
    return "zh";
  }

  let langCode = userLanguage.substring(0, 2).toLowerCase();
  console.log("ROCK we get the Initial language is", langCode, userLanguage);
  if (langCode === "zh") {
    return "zh";
  } else {
    return "en";
  }
}

const initialState = {
  deviceName: "", // current selected devices.

  // all the once connected devices, user has these devices.
  deviceList: [], // {text, picture}
  deviceParams: {
    layoutX: 2800,
    layoutY: 1200,
    layout: "", // layout data url.
    layoutPicture: "",
    layoutPicture_empty: "",
    layoutMappingData: [], //the content of layout link
  },

  // all the supported devices, maybe not connected.
  productList: [],
  fatalLog: "",

  appConfig: {
    lang: getInitialLanguage(),
    languageArray: [
      ["English", "/uk.png", "en"],
      ["简体中文", "/chinese.png", "zh"],
      ["French", "/france.png", "en"],
      ["German", "/german.png", "en"],
      ["Spanish", "/spanish.png", "en"],
      ["Russian", "/russian.png", "en"],
      ["Japanese", "/japan.png", "en"],
    ],
  },

  macroBufferSize: "",
  keyboardBelowClickValueForDrag: null,
  keyboardBelowClickUidForDrag: null,

  isLight: true,
  // below is device specific.
  default: {
    topMenuIndex: 0,
    //drag.
    dragFlag: false,
    mouseDownX: 0,
    mouseDownY: 0,
    mouseUpY: -800,
    mouseUpX: -800,

    mapPendingKey: [],
    mapPendingSuperKey: [],
    keyboardBelowClickValue: -1,
    keyboardBelowClickUid: -1, // uid
    belowUpdateFlag: false,
    userCombineKeys: null,

    hintText: "",
    hintSource: "",
    mask: false,
    showConfigureUI: false,

    buttonMap: {
      //ButtonMap 按键映射。
      // 每一个按键可以映射成任意按键，甚至是不存在的多媒键，组合键，鼠标按键以及录制的宏。
      // 所以需要抽象出一个对象来标示要映射的对象。
      // The Key map assigns a function to the key. This can be a normal key-press, or a special feature
      // (macro, media-key, etc) Each key has a two-byte configuration.
      layerIndex: 0,
      // Row * 100 + column ==> new buttonMapObj(index, value_UID).
      // 总计有四层，用上面的layerIndex来表示当前层。
      mappingData: [{}, {}, {}, {}], // keyid:{showText, uid}
      bottomTabIndex: 0, //底层按的Ｔab索引，　０开始计数，也是buttonMapObj中的tabIndex算是映射类别。
    },

    lightness: {
      effectId: 0,
      speed: 60,
      hexcolor: "#ffffff",
      brightness: 60,
      color: "#153DDF",
      progress: "100",
      mappingData: {},
    },
    superkeys: {
      mappingData: {},
      socdKeys: [],
      endKeys: [],
      tglKeys: [],
      mtKeys: [],
      mptKeys: [],
    },
    settings: {
      sleeptime: 0,
      game_disable_list: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      theme: 0,
      isLight: true,
      language: getInitialLanguage() === "zh" ? 1 : 0,

      firmwareVersion: "unknown",
      batteryInfo: "64",
      deviceSN: "",
    },

    macroMap: {
      selectIndex: 0,
      defaultMacros: [
        { name: "M1", storeIndex: "77 00", actionList: [] },
        { name: "M2", storeIndex: "77 01", actionList: [] },
        { name: "M3", storeIndex: "77 02", actionList: [] },
        { name: "M4", storeIndex: "77 03", actionList: [] },
        { name: "M5", storeIndex: "77 04", actionList: [] },
        { name: "M6", storeIndex: "77 05", actionList: [] },
        { name: "M7", storeIndex: "77 06", actionList: [] },
        { name: "M8", storeIndex: "77 07", actionList: [] },
        { name: "M9", storeIndex: "77 08", actionList: [] },
        { name: "M10", storeIndex: "77 09", actionList: [] },
        { name: "M11", storeIndex: "77 0A", actionList: [] },
        { name: "M12", storeIndex: "77 0B", actionList: [] },
        { name: "M13", storeIndex: "77 0C", actionList: [] },
        { name: "M14", storeIndex: "77 0D", actionList: [] },
        { name: "M15", storeIndex: "77 0E", actionList: [] },
        { name: "M16", storeIndex: "77 0F", actionList: [] },
      ],
    },
    fastTrigger: {
      mappingData: {},
      travelValue: 1.66,
      isRTEnabled: false,
      sensitivity: 1.66,
      isIndependentSensitivity: false,
      triggerSensitivity: 1.66,
      resetSensitivity: 1.66,
    },
    calibrationMap: {
      keyLogs: [],
    },
    keyValues: [], // Changed from Map to array
  },
};

function commonReducer(state = initialState, action) {
  //console.log("We reducer get action ", action, "our old state is", state);
  let device = state.deviceName + "-Configure";
  let deviceConfigures = null;
  if (state[device]) {
    deviceConfigures = JSON.parse(JSON.stringify(state[device]));
  }

  switch (action.type) {
    case CONNECTED_DEVICE:
      if (action.payload) {
        updateGlobalState({ deviceName: action.productName });
        if (state[action.productName + "-Configure"]) {
          return {
            ...state,
            deviceName: action.productName,
          };
        } else {
          let defaultConfigures = JSON.parse(JSON.stringify(state.default));

          return {
            ...state,
            [action.productName + "-Configure"]: defaultConfigures,
            deviceName: action.productName,
          };
        }
      } else {
        return {
          ...state,
          deviceName: action.productName,
        };
      }

    case SET_FATAL_LOG:
      return { ...state, fatalLog: action.payload };
    case "USER_CLICK_TOP_MENU_ACTION":
      deviceConfigures.topMenuIndex = action.payload;
      deviceConfigures.mapPendingKey = [];
      return { ...state, [device]: deviceConfigures };

    case "USER_CLICK_LAYER_ACTION": {
      let buttonMap = deviceConfigures.buttonMap;
      buttonMap.layerIndex = action.payload;
      return { ...state, [device]: deviceConfigures };
    }

    case "USER_CLICK_BOTTOM_TAB_ACTION": {
      let buttonMap = deviceConfigures.buttonMap;
      buttonMap.bottomTabIndex = action.payload;
      return { ...state, [device]: deviceConfigures };
    }

    case "FILL_MACRO_BUFFER_SIZE": {
      return { ...state, macroBufferSize: action.payload };
    }

    case "USER_CLICK_DOWN_COORDINATE": {
      deviceConfigures.mouseDownX = action.x;
      deviceConfigures.mouseDownY = action.y;
      console.log("We get Distance is", action.x, action.y);
      return {
        ...state,
        [device]: deviceConfigures,
      };
    }

    case "USER_CLICK_START_REAL_DRAG_ACTION": {
      deviceConfigures.dragFlag = true;
      return {
        ...state,
        [device]: deviceConfigures,
      };
    }

    case "USER_CLICK_START_DRAG_ACTION": {
      console.warn(
        "USER_CLICK_START_DRAG set below click value",
        action.payload
      );
      deviceConfigures.keyboardBelowClickValue = action.payload;
      deviceConfigures.keyboardBelowClickUid = action.uid;
      console.warn("CLICK_START_DRAG update below value", action.payload);
      return {
        ...state,
        [device]: deviceConfigures,
        keyboardBelowClickValueForDrag: action.payload,
        keyboardBelowClickUidForDrag: action.uid,
      };
    }

    case "USER_CLICK_STOP_DRAG_ACTION": {
      deviceConfigures.dragFlag = false;

      return {
        ...state,
        [device]: deviceConfigures,
        keyboardBelowClickValueForDrag: null,
        keyboardBelowClickUidForDrag: null,
      };
    }

    // ROCK
    // userSetDragMappingAction
    case "USER_SET_DRAG_MAPPING_ACTION": {
      let buttonMap2 = deviceConfigures.buttonMap;

      if (deviceConfigures.topMenuIndex === 0) {
        deviceConfigures.mapPendingKey = [...action.payload];
      } else if (deviceConfigures.topMenuIndex >= 1) {
        deviceConfigures.mapPendingKey = [
          ...deviceConfigures.mapPendingKey,
          ...action.payload,
        ];
      }
      console.warn("USER_SET_DRAG_MAPPING_ACTION", action);

      /*
      if (state.keyboardBelowClickValueForDrag !== null) {
        deviceConfigures.keyboardBelowClickValue =
          state.keyboardBelowClickValueForDrag;
        deviceConfigures.keyboardBelowClickUid =
          state.keyboardBelowClickUidForDrag;
      }
      */

      if (deviceConfigures.keyboardBelowClickValue.length > 0) {
        console.warn(
          "USER_SET_DRAG_MAPPING_ACTION map new key to value",
          deviceConfigures.mapPendingKey,
          " ==> " + deviceConfigures.keyboardBelowClickValue
        );

        let currentLayerMapArray = getMappingData(
          deviceConfigures,
          deviceConfigures.topMenuIndex
        );

        currentLayerMapArray[deviceConfigures.mapPendingKey] = {
          showText: deviceConfigures.keyboardBelowClickValue,
          uid: deviceConfigures.keyboardBelowClickUid,
        };

        for (const singleKey of deviceConfigures.mapPendingKey) {
          const keys = singleKey.split(",");

          for (const key of keys) {
            // "<," singlekey, split will generate "<" and "" two elements.
            if (key.length === 0) {
              continue;
            }

            let keyObj = getMiddleKeyObjectById(
              state.deviceParams.layoutMappingData,
              key
            );

            const row = keyObj.row;
            const col = keyObj.col;
            //Send data to hid.
            let command =
              "05 " +
              buttonMap2.layerIndex.toString(16).padStart(2, "0") +
              " " +
              row.toString(16).padStart(2, "0") +
              " " +
              col.toString(16).padStart(2, "0") +
              " " +
              deviceConfigures.keyboardBelowClickUid;

            console.log(
              `(${row}, ${col}) => ${deviceConfigures.keyboardBelowClickUid}`
            );
            console.warn("We input command is", command);
            SendDataToHID(command);
          }
        }

        deviceConfigures.mapPendingKey = [];
      }

      return { ...state, [device]: deviceConfigures };
    }

    case "CLEAR_ALL_BUTTON_MAP": {
      const updatedDeviceConfigures = { ...deviceConfigures };
      let currentLayerMapArray = getMappingData(
        updatedDeviceConfigures,
        updatedDeviceConfigures.topMenuIndex
      );

      updatedDeviceConfigures.mapPendingKey = [];
      console.log("We current map is", currentLayerMapArray);
      Object.keys(currentLayerMapArray).forEach((key) => {
        delete currentLayerMapArray[key];
      });
      updatedDeviceConfigures.belowUpdateFlag =
        !updatedDeviceConfigures.belowUpdateFlag;

      if (updatedDeviceConfigures.topMenuIndex === 3) {
        updatedDeviceConfigures.keyboardBelowClickValue = [];
        updatedDeviceConfigures.superkeys = {
          mappingData: {},
          socdKeys: [],
          endKeys: [],
          tglKeys: [],
          mtKeys: [],
          mptKeys: [],
        };
        const command = ["33", "05", "00", "ff", "ff", "ff", "01"].join(" ");
        console.log("发送 SOCD 命令:", command);
        SendDataToHID(command);
        const command1 = ["33", "06", "00", "ff", "ff", "ff", "01"].join(" ");
        console.log("发送 SOCD 命令:", command);
        SendDataToHID(command1);
      }
      SendDataToHID("06");
      return { ...state, [device]: updatedDeviceConfigures };
    }

    // ROCK userClickKeyboardBelowAction
    case "USER_CLICK_KEYBOARD_BELOW": {
      let buttonMap2 = deviceConfigures.buttonMap;

      deviceConfigures.keyboardBelowClickValue = action.payload;
      deviceConfigures.belowUpdateFlag = !deviceConfigures.belowUpdateFlag;

      console.warn(
        "CLICK_DIRECT_CLICK update below value",
        action,
        deviceConfigures.mapPendingKey
      );
      deviceConfigures.keyboardBelowClickUid = action.uid;

      if (deviceConfigures.mapPendingKey.length > 0) {
        console.warn(
          "CLICK_DIRECT_CLICK update below value",
          action.payload,
          " setup the new mapping",
          deviceConfigures.mapPendingKey,
          " ==> " + action.payload
        );
        let currentLayerMapArray = getMappingData(
          deviceConfigures,
          deviceConfigures.topMenuIndex
        );

        currentLayerMapArray[deviceConfigures.mapPendingKey] = {
          showText: action.payload,
          uid: action.uid,
          combine: action.combine,
        };

        for (const singleKey of deviceConfigures.mapPendingKey) {
          const keys = singleKey.split(",");

          for (const key of keys) {
            // "<," singlekey, split will generate "<" and "" two elements.
            if (key.length === 0) {
              continue;
            }
            if (deviceConfigures.topMenuIndex !== 3) {
              let keyObj = getMiddleKeyObjectById(
                state.deviceParams.layoutMappingData,
                key
              );

              const row = keyObj.row;
              const col = keyObj.col;
              //Send data to hid.
              let command =
                "05 " +
                buttonMap2.layerIndex.toString(16).padStart(2, "0") +
                " " +
                row.toString(16).padStart(2, "0") +
                " " +
                col.toString(16).padStart(2, "0") +
                " " +
                deviceConfigures.keyboardBelowClickUid;
              console.log(
                `(${row}, ${col}) => ${deviceConfigures.keyboardBelowClickUid}`
              );
              console.warn("We input command is", command);
              SendDataToHID(command);
            }
          }
        }

        //deviceConfigures.mapPendingKey = [];
      }

      return { ...state, [device]: deviceConfigures };
    }

    case "USER_CLICK_MAP_PENDING_KEY": {
      deviceConfigures.mapPendingKey = action.payload;
      deviceConfigures.mapPendingSuperKey = action.payload;
      console.log(
        "deviceConfigures.mapPendingKey",
        deviceConfigures.mapPendingKey
      );
      deviceConfigures.keyboardBelowClickUid = "invalid";
      deviceConfigures.keyboardBelowClickValue = "none";
      //console.warn("MIDDLE_CLICK_CANVAS", action.payload);
      return { ...state, [device]: deviceConfigures };
    }

    case "USER_CLICK_LIGHTNESS_ACTION":
      Object.keys(action.payload).forEach((category) => {
        let value = action.payload[category];
        let commandId = "07 03";

        if (category === "bright") {
          deviceConfigures.lightness.brightness = value;
          commandId += " 01 ";
          commandId += percentageToHexByte(value);
          SendDataToHID(commandId);
        } else if (category === "effectid") {
          deviceConfigures.lightness.effectId = value;

          commandId += " 02 ";
          commandId += value.toString(16).padStart(2, "0");
          SendDataToHID(commandId);
        } else if (category === "speed") {
          deviceConfigures.lightness.speed = value;
          commandId += " 03 ";
          commandId += percentageToHexByte(value);
          SendDataToHID(commandId);
        } else if (category === "color") {
          deviceConfigures.lightness.color = value;
          commandId += " 04 ";

          let hsv = hexToHSV(value);

          commandId +=
            Math.floor(hsv.h).toString(16).padStart(2, "0") +
            " " +
            Math.floor(hsv.s).toString(16).padStart(2, "0") +
            " " +
            Math.floor(hsv.v).toString(16).padStart(2, "0");

          debouncedSaveData(commandId);
        } else if (category === "hexcolor") {
          deviceConfigures.lightness.hexcolor = value;
        } else if (category === "progress") {
          deviceConfigures.lightness.progress = value;
        }
      });

      return { ...state, [device]: deviceConfigures };

    //这里拉取的是产品列表数据，vid，pid，以及图片和布局数据。
    case "USER_SAVE_PRODUCT_LIST": {
      return { ...state, productList: action.payload };
    }

    case "USER_CLICK_RESET_TO_FACTORY": {
      SendDataToHID("F1 FE");

      let defaultConfigures = JSON.parse(JSON.stringify(state.default));

      return {
        ...initialState,
        [state.deviceName + "-Configure"]: defaultConfigures,
      };
    }

    case "USER_CLICK_CHECK_UPDATE": {
      return { ...state };
    }

    case "USER_UPDATE_LAYOUT_DATA": {
      console.log(
        "ROCK we update the layoutMappingData to",
        action.payload.length
      );
      let deviceParams = JSON.parse(JSON.stringify(state.deviceParams));
      deviceParams.layoutMappingData = action.payload;
      return { ...state, deviceParams: deviceParams };
    }

    case "USER_ADD_NEW_DEVICE": {
      let item = state.productList.find(
        (item) => item.vid === action.vid && item.pid === action.pid
      );

      console.log(
        "We ROCK USER_ADD_NEW_DEVICE item",
        item,
        state.productList,
        action
      );

      let newObj = {
        picture: item.picture,
        type: action.name, // this is the product name.
        vid: action.vid,
        pid: action.pid,
      };

      let deviceParams = {
        layout: item.layout,
        layoutPicture: item.keyboard,
        layoutPicture_empty: item.keyboard_empty,
        layoutX: item.layoutX,
        layoutY: item.layoutY,
      };

      if (!state.deviceList.some((item) => item.type === action.name)) {
        let deviceList = JSON.parse(JSON.stringify(state.deviceList));
        deviceList.push(newObj);

        return {
          ...state,
          deviceParams: deviceParams,
          deviceList: deviceList,
        };
      } else {
        return { ...state, deviceParams: deviceParams };
      }
    }

    case "SETTING_SET_GAME_DISABLE_LIST": {
      let settings = deviceConfigures.settings;
      settings.game_disable_list = action.payload;

      let disableKeyLists = [];

      for (let i = 0; i < action.payload.length; i++) {
        let keys = gameDisable[i][1].split("|");
        keys.forEach((item, index) => {
          if (action.payload[i]) {
            disableKeyLists.push(item);
          }
        });
      }

      let command = "31 03";
      let encodeData = "";
      let offset = 0;
      let lengthPackage = disableKeyLists.length * 2;
      console.log("Game mode send key", disableKeyLists);
      const maxPackageKeyCount = 14; // 32 - (31 03 offset length) = 28/2 = 14
      for (let i = 0; i < disableKeyLists.length; i++) {
        encodeData += disableKeyLists[i] + " ";
        offset = Math.floor(i / maxPackageKeyCount) * maxPackageKeyCount * 2;
        if (
          i % maxPackageKeyCount === maxPackageKeyCount - 1 ||
          i === disableKeyLists.length - 1
        ) {
          command += " 00 00 "; // offset 00 00
          //这个length计算不对，但是我们这里只有一次循环就够了，可以先忽略。
          command += lengthPackage.toString(16).padStart(2, "0") + " ";
          console.log("We encode Data is", encodeData);

          command += encodeData;
          command = command.trim();
          console.warn("We composeed command is", command);

          SendDataToHID(command);
          command = "31 03";
          encodeData = "";
        }
      }

      if (disableKeyLists.length === 0) {
        SendDataToHID("31 03");
      }

      return { ...state, [device]: deviceConfigures };
    }

    case "SETTING_SET_RADIO_CHOICE": {
      let settings = deviceConfigures.settings;
      settings[action.name] = action.payload;

      if (action.name === "sleeptime") {
        //SendDataToHID("31 02")
        // page 19.
        let command = "31 02 ";

        command += action.payload.toString(16).padStart(2, "0") + " 00";
        command += " 00 00"; //(no Suspend) 0x0000
        SendDataToHID(command);
      } else if (action.name === "theme") {
        settings.isLight = action.payload === 0;
        return {
          ...state,
          [device]: deviceConfigures,
          isLight: settings.isLight,
        };
      }

      return { ...state, [device]: deviceConfigures };
    }

    case "SEND_ALERT_HINTS": {
      let macroMap = deviceConfigures.macroMap;
      deviceConfigures.hintText = action.payload;

      deviceConfigures.mask = true;
      if (typeof action.source !== "string") {
        macroMap.selectIndex = action.source;
      }
      deviceConfigures.hintSource = action.source;
      return { ...state, [device]: deviceConfigures };
    }

    case "SELECT_MACRO_ITEM": {
      let macroMap = deviceConfigures.macroMap;
      macroMap.selectIndex = action.payload;
      return { ...state, [device]: deviceConfigures };
    }

    case "BLUR_MAIN_PAGE": {
      deviceConfigures.mask = action.payload;
      return { ...state, [device]: deviceConfigures };
    }

    case "POPUP_CONFIGURE_UI": {
      let macroMap = deviceConfigures.macroMap;
      deviceConfigures.hintText = "";
      deviceConfigures.showConfigureUI = action.payload;
      macroMap.selectIndex = action.index;
      deviceConfigures.mask = true;
      return { ...state, [device]: deviceConfigures };
    }

    case "USER_IMPORT_WHOLE_CONFIG": {
      let currentPageIndex = action.index; // 1 2 3
      console.log("We import for configure " + currentPageIndex);
      return { ...state, [device]: action.payload };
    }

    case "MACRO_REPLACED": {
      // Save data to configuration in localstorage.
      let macroMap = deviceConfigures.macroMap;
      let defaultMacros = JSON.parse(JSON.stringify(macroMap.defaultMacros));
      defaultMacros[action.index] = action.payload;
      macroMap.defaultMacros = defaultMacros;

      // Send data to HID.
      // 1. reset whole macro buffer.
      SendDataToHID("10");

      // Calac the buffer size.
      let num = parseInt(state.macroBufferSize.replace(/\s+/g, ""), 16);
      num -= 1;
      let bufferSize = convertIntToHexStringKeep(num, 2);
      console.log(
        "We try to get buffer size is",
        num,
        "buffer size is",
        bufferSize
      );

      // 2. data begin frame.
      SendDataToHID("0F " + bufferSize + " 01 FF");

      // Compose Macros Data.
      let macroListData = "";
      defaultMacros.forEach((item, indexO) => {
        let macro = "";

        item.actionList.forEach((action, indexI) => {
          // console.log(
          //   "Macro data is",
          //   action.name,
          //   action.isDown,
          //   action.code,
          //   action.duration
          // );

          // calc the duration number.
          let duration = parseFloat(action.duration);
          duration = Math.floor(duration);

          let times = duration === 0 ? "30 " : "";
          while (duration > 0) {
            let lastPos = duration % 10;
            times += convertIntToHexString(lastPos + 0x30, 1) + " ";
            duration = Math.floor(duration / 10);
          }

          duration = times.trim().split(" ").reverse();

          let cmdId = getUidByKeyCode(action.code).split(" ")[1];
          let part = action.isDown ? "01 02 " : "01 03 ";
          let actionCommand =
            part + cmdId + " 01 04 " + duration.join(" ") + " 7C ";

          macro += actionCommand;

          console.log("duration is ", duration, actionCommand);
        });

        if (indexO !== defaultMacros.length - 1) {
          macroListData += macro + "00 ";
        }
      });

      console.log(
        "ROCK ZHANG",
        macroListData,
        macroListData.split(" ").join("")
      );

      const PACK_SIZE = 0x1c; //28
      macroListData = macroListData.split(" ").join("");

      for (let i = 0; i < macroListData.length / 2; i = i + PACK_SIZE) {
        let singleCommand = "0F " + convertIntToHexStringKeep(i, 2) + " ";

        let currentPackageLen =
          macroListData.length / 2 - i > PACK_SIZE
            ? PACK_SIZE
            : macroListData.length / 2 - i;
        singleCommand += convertIntToHexString(currentPackageLen, 1);

        let middle = macroListData.slice(i * 2, currentPackageLen * 2 + i * 2);

        //console.log("ROCK ZHANG", currentPackageLen, middle);
        const formattedMiddle = middle.match(/.{1,2}/g).join(" ");

        singleCommand += " " + formattedMiddle + " ";

        console.log("ROCK ZHANG==> SingleCommand: =>", singleCommand);

        // SendDataToHID

        SendDataToHID(singleCommand);
        singleCommand = "";
      }

      // data end frame.
      SendDataToHID("0F " + bufferSize + " 01");

      return { ...state, [device]: deviceConfigures };
    }

    case "CLEAR_ALL_POPUP": {
      deviceConfigures.hintText = "";
      deviceConfigures.showConfigureUI = false;
      deviceConfigures.mask = false;
      return { ...state, [device]: deviceConfigures };
    }

    // draw dragged blue circle.
    case "USER_CLICK_MOUSE_UP_POS": {
      deviceConfigures.mouseUpX = action.x;
      deviceConfigures.mouseUpY = action.y;
      return { ...state, [device]: deviceConfigures };
    }

    case CHANGE_APP_LANG:
      let currentconfig = { ...state.appConfig };
      currentconfig.lang = action.payload;

      let result = { ...state, appConfig: currentconfig };
      console.log("We get app config", currentconfig, result);
      return result;

    case "RESTORE_ALL_CONFIGS":
      return initialState;

    case "FILL_FIRMWARE_VERSION": {
      let settings = deviceConfigures.settings;
      settings.firmwareVersion = action.payload;
      return { ...state, [device]: deviceConfigures };
    }

    case "FILL_BATTERY_INFO": {
      let settings = deviceConfigures.settings;
      settings.batteryInfo = action.payload;
      return { ...state, [device]: deviceConfigures };
    }

    case "SET_FAST_TRIGGER_TRAVEL_VALUE": {
      const updatedDeviceConfigures = {
        ...deviceConfigures,
        fastTrigger: {
          ...deviceConfigures.fastTrigger,
          travelValue: action.payload,
        },
      };
      return { ...state, [device]: updatedDeviceConfigures };
    }

    case "SET_FAST_TRIGGER_RT_ENABLED": {
      const updatedDeviceConfigures = {
        ...deviceConfigures,
        fastTrigger: {
          ...deviceConfigures.fastTrigger,
          isRTEnabled: action.payload,
        },
      };
      return { ...state, [device]: updatedDeviceConfigures };
    }

    case "SET_FAST_TRIGGER_SENSITIVITY": {
      const updatedDeviceConfigures = {
        ...deviceConfigures,
        fastTrigger: {
          ...deviceConfigures.fastTrigger,
          sensitivity: action.payload,
        },
      };
      return { ...state, [device]: updatedDeviceConfigures };
    }

    case "SET_FAST_TRIGGER_INDEPENDENT_SENSITIVITY": {
      const updatedDeviceConfigures = {
        ...deviceConfigures,
        fastTrigger: {
          ...deviceConfigures.fastTrigger,
          isIndependentSensitivity: action.payload,
        },
      };
      return { ...state, [device]: updatedDeviceConfigures };
    }

    case "SET_FAST_TRIGGER_TRIGGER_SENSITIVITY": {
      const updatedDeviceConfigures = {
        ...deviceConfigures,
        fastTrigger: {
          ...deviceConfigures.fastTrigger,
          triggerSensitivity: action.payload,
        },
      };
      return { ...state, [device]: updatedDeviceConfigures };
    }

    case "SET_FAST_TRIGGER_RESET_SENSITIVITY": {
      const updatedDeviceConfigures = {
        ...deviceConfigures,
        fastTrigger: {
          ...deviceConfigures.fastTrigger,
          resetSensitivity: action.payload,
        },
      };
      return { ...state, [device]: updatedDeviceConfigures };
    }

    case "SEND_FAST_TRIGGER_SETTINGS_TO_HID": {
      const triggerSettings = action.payload;
      console.log("triggerSettings", triggerSettings);
      const travelValueInt = Math.round(triggerSettings[0] * 100);
      let sensitivityInt = null;
      let resetSensitivityInt = null;
      let triggerSensitivityInt = null;
      if (triggerSettings[1] && triggerSettings[3] === false) {
        sensitivityInt = Math.round(triggerSettings[2].sensitivity * 100);
        triggerSensitivityInt = sensitivityInt;
        resetSensitivityInt = sensitivityInt;
      } else if (triggerSettings[1] && triggerSettings[3] === true) {
        triggerSensitivityInt = Math.round(
          triggerSettings[4].triggerSensitivity * 100
        );
        resetSensitivityInt = Math.round(
          triggerSettings[5].resetSensitivity * 100
        );
      }

      // const command = [
      //   "33",
      //   "03",
      //   "01",
      //   "00",
      //   row.toString(16).padStart(2, "0"),
      //   col.toString(16).padStart(2, "0"),
      //   travelValueInt.toString(16).padStart(4, "0").slice(0, 2) + " " + travelValueInt.toString(16).padStart(4, "0").slice(2, 4),
      //   triggerSettings[1] ? "01" : "00",
      //   resetSensitivityInt.toString(16).padStart(4, "0").slice(0, 2) + " " + resetSensitivityInt.toString(16).padStart(4, "0").slice(2, 4),
      //   triggerSettings[3] ? "01" : "00",
      //   triggerSensitivityInt.toString(16).padStart(4, "0").slice(0, 2) + " " + triggerSensitivityInt.toString(16).padStart(4, "0").slice(2, 4)
      // ].join(" ");

      // console.log("SEND_FAST_TRIGGER_SETTINGS_TO_HID command:", command);
      // SendDataToHID(command);

      // const updatedDeviceConfigures = {
      //   ...deviceConfigures,
      //   fastTrigger: {
      //     ...deviceConfigures.fastTrigger,
      //     travelValue: triggerSettings[0],
      //     isRTEnabled: triggerSettings[1],
      //     sensitivity: triggerSettings[2],
      //     isIndependentSensitivity: triggerSettings[3],
      //     triggerSensitivity: triggerSettings[4],
      //     resetSensitivity: triggerSettings[5]
      //   }
      // };

      // return { ...state, [device]: updatedDeviceConfigures };
    }

    case "FAST_TRIGGER_INFORMATION": {
      console.log("Received fast trigger data:", action.payload);
      const data = action.payload;
      const row = parseInt(data[4], 16);
      const column = parseInt(data[5], 16);
      const keys = getMiddleKeysData();
      const keyId = keys.find(
        (key) => key.row === row && key.col === column
      )?.id;

      if (!keyId) {
        console.warn("No key found for row:", row, "column:", column);
        return state;
      }

      // 解析行程值
      const travelValueHex = data[6] + data[7];
      const travelValue = parseInt(travelValueHex, 16) / 100;

      // 解析RT启用状态
      const isRTEnabled = data[8] === "01";

      // 解析灵敏度设置
      let sensitivity = null;
      let resetSensitivity = null;
      let triggerSensitivity = null;
      const isIndependentSensitivity = data[11] === "01";

      if (isRTEnabled) {
        if (isIndependentSensitivity) {
          // 独立灵敏度模式
          const resetSensitivityHex = data[9] + data[10];
          resetSensitivity = parseInt(resetSensitivityHex, 16) / 100;
          const triggerSensitivityHex = data[12] + data[13];
          triggerSensitivity = parseInt(triggerSensitivityHex, 16) / 100;
        } else {
          // 统一灵敏度模式
          const sensitivityHex = data[9] + data[10];
          sensitivity = parseInt(sensitivityHex, 16) / 100;
          resetSensitivity = sensitivity;
          triggerSensitivity = sensitivity;
        }
      }

      const newKeyData = {
        id: keyId,
        travelValue,
        isRTEnabled,
        sensitivity,
        isIndependentSensitivity,
        triggerSensitivity,
        resetSensitivity,
      };

      console.log("deviceConfigures.keyValues", deviceConfigures.keyValues);
      const currentKeyValues = Array.isArray(deviceConfigures.keyValues)
        ? deviceConfigures.keyValues
        : [];
      const existingKeyIndex = currentKeyValues.findIndex(
        (k) => k.id === keyId
      );

      console.log("existingKeyIndex", existingKeyIndex);

      // const existingKeyIndex = (deviceConfigures.keyValues || []).findIndex(k => k.id === keyId);

      let newKeyValues;
      if (existingKeyIndex !== -1) {
        newKeyValues = [...deviceConfigures.keyValues];
        newKeyValues[existingKeyIndex] = newKeyData;
      } else {
        newKeyValues = [...deviceConfigures.keyValues, newKeyData];
      }

      console.log("Updated key values for key:", keyId, newKeyValues);

      const newDeviceConfigures = {
        ...deviceConfigures,
        keyValues: newKeyValues,
      };

      console.log("newDeviceConfigures", newDeviceConfigures);

      return {
        ...state,
        [device]: newDeviceConfigures,
      };
    }

    case "START_CALIBRATION":
      SendDataToHID("f1 01 00 00 01");
      return { ...state, [device]: deviceConfigures };

    case "END_CALIBRATION":
      SendDataToHID("f1 01 00 00 02");
      return { ...state, [device]: deviceConfigures };

    case "DISABLE_KEY_REPORTING":
      SendDataToHID("f1 31 01");
      return { ...state, [device]: deviceConfigures };

    case "SHOW_KEY_LOG": {
      console.log("asdadasdased", action.payload);
      const { keyLogs } = action.payload;
      console.log("calibrationMap", keyLogs);

      const uniqueKeyLogs = [...new Set(keyLogs)];

      if (!deviceConfigures.calibrationMap) {
        deviceConfigures.calibrationMap = {
          keyLogs: [],
        };
      }

      let newCalibrationMap = { ...deviceConfigures.calibrationMap };

      newCalibrationMap = {
        ...newCalibrationMap,
        keyLogs: uniqueKeyLogs, // 更新去重后的 keyLogs
      };

      const newDeviceConfigures = {
        ...deviceConfigures,
        calibrationMap: newCalibrationMap,
      };

      console.log("newCalibrationMap", newDeviceConfigures);

      return {
        ...state,
        [device]: newDeviceConfigures,
      };
    }

    case "USER_COMBINED_RESULT_ACTION":
      deviceConfigures.userCombineKeys = action.payload;
      return { ...state, [device]: deviceConfigures };

    case "UPDATE_KEY_VALUES": {
      console.log("Updating key values:", action.payload);
      const { keyId, values } = action.payload;

      if (!keyId) return;
      // 查找是否存在相同 id 的对象
      const existingKeyIndex = deviceConfigures.keyValues.findIndex(
        (k) => k.id === keyId
      );
      console.log("existingKeyIndex", existingKeyIndex);

      // 如果存在相同 id 的对象，合并值；否则创建新对象
      const newKeyData =
        existingKeyIndex !== -1
          ? { ...deviceConfigures.keyValues[existingKeyIndex], ...values } // 合并值
          : { id: keyId, ...values }; // 创建新对象

      console.log("newKeyData", newKeyData);

      // 更新数组
      const newKeyValues =
        existingKeyIndex !== -1
          ? deviceConfigures.keyValues.map((item, index) =>
              index === existingKeyIndex ? newKeyData : item
            ) // 更新现有对象
          : [...deviceConfigures.keyValues, newKeyData]; // 新增对象

      console.log("newKeyValues", newKeyValues);

      let keyObj = getMiddleKeyObjectById(
        state.deviceParams.layoutMappingData,
        keyId
      );
      const row = keyObj.row;
      const col = keyObj.col;

      const travelValueInt = Math.round(
        parseFloat(newKeyData.travelValue) * 100
      );
      let sensitivityInt = Math.round(parseFloat(newKeyData.sensitivity) * 100);
      let resetSensitivityInt = Math.round(
        parseFloat(newKeyData.resetSensitivity) * 100
      );
      let triggerSensitivityInt = Math.round(
        parseFloat(newKeyData.triggerSensitivity) * 100
      );

      // 根据模式设置灵敏度值
      if (newKeyData.isRTEnabled && !newKeyData.isIndependentSensitivity) {
        resetSensitivityInt = sensitivityInt;
        triggerSensitivityInt = sensitivityInt;
      }

      // 确保所有值都被正确初始化
      if (!sensitivityInt) sensitivityInt = 166;
      if (!resetSensitivityInt) resetSensitivityInt = 166;
      if (!triggerSensitivityInt) triggerSensitivityInt = 166;

      console.log("travelValueInt", travelValueInt);
      console.log("resetSensitivityInt1", resetSensitivityInt);
      console.log("triggerSensitivityInt2", triggerSensitivityInt);

      const command = [
        "33",
        "03",
        "01",
        "00",
        row.toString(16).padStart(2, "0"),
        col.toString(16).padStart(2, "0"),
        travelValueInt.toString(16).padStart(4, "0").slice(0, 2) +
          " " +
          travelValueInt.toString(16).padStart(4, "0").slice(2, 4),
        newKeyData.isRTEnabled ? "01" : "00",
        resetSensitivityInt.toString(16).padStart(4, "0").slice(0, 2) +
          " " +
          resetSensitivityInt.toString(16).padStart(4, "0").slice(2, 4),
        newKeyData.isIndependentSensitivity ? "01" : "00",
        triggerSensitivityInt.toString(16).padStart(4, "0").slice(0, 2) +
          " " +
          triggerSensitivityInt.toString(16).padStart(4, "0").slice(2, 4),
      ].join(" ");
      console.log("Update command", command);
      SendDataToHID(command);

      const newDeviceConfigures = {
        ...deviceConfigures,
        keyValues: newKeyValues,
      };

      console.log("newDeviceConfigures", newDeviceConfigures);

      return {
        ...state,
        [device]: newDeviceConfigures,
      };
    }

    case "UPDATE_SUPERKEYS": {
      const { type, keys, deletedKeyboard } = action.payload;
      console.log("asdasd1212121231", keys);

      // 确保 superkeys 对象存在
      if (!deviceConfigures.superkeys) {
        deviceConfigures.superkeys = {
          mappingData: {},
          dskKeys: [],
          socdKeys: [],
          endKeys: [],
          tglKeys: [],
          mtKeys: [],
          mptKeys: [],
        };
      }

      // 创建新的 superkeys 对象
      let newSuperkeys = { ...deviceConfigures.superkeys };

      console.log("new", newSuperkeys);

      // 根据类型更新对应的数组
      if (type === "end") {
        newSuperkeys.endKeys = keys || [];
      } else if (type === "tgl") {
        newSuperkeys.tglKeys = keys || [];
      } else if (type === "mt") {
        newSuperkeys.mtKeys = keys || [];
      } else if (type === "mpt") {
        newSuperkeys.mptKeys = keys || [];
      } else if (type === "socd") {
        newSuperkeys.socdKeys = keys || [];
      } else if (type === "dsk") {
        newSuperkeys.dskKeys = keys || [];
      }

      console.log("newSuperkeys", newSuperkeys);
      const keyName = Object.keys(newSuperkeys.mappingData)[0];
      let keyObj = getMiddleKeyObjectById(
        state.deviceParams.layoutMappingData,
        keyName
      );
      console.log("keyObj", keyObj);
      const row = keyObj.row;
      const col = keyObj.col;

      let currentLayerMapArray = getMappingData(
        deviceConfigures,
        deviceConfigures.topMenuIndex
      );

      if (type === "socd") {
        const command = [
          "33",
          "06",
          "01",
          "00",
          row.toString(16).padStart(2, "0"),
          col.toString(16).padStart(2, "0"),
          "06",
          "00",
          "00",
          "00",
          "00",
          "00",
        ].join(" ");
        console.log("发送 SOCD 命令:", command);
        SendDataToHID(command);
      } else if (type === "dsk") {
        const command = [
          "33",
          "05",
          "00",
          "00",
          row.toString(16).padStart(2, "0"),
          col.toString(16).padStart(2, "0"),
          "01",
          "00",
          "00",
          "00",
          "00",
          "00",
          "00",
          "00",
          "00",
          "00",
          "00",
          "00",
          "00",
          "00",
          "00",
          "00",
          "00",
        ].join(" ");
        SendDataToHID(command);
      } else if (type === "mpt") {
        const command = [
          "33",
          "05",
          "01",
          "00",
          row.toString(16).padStart(2, "0"),
          col.toString(16).padStart(2, "0"),
          "04",
          "00",
          "00",
          "00",
          "00",
          "00",
          "00",
          "00",
          "00",
          "00",
          "00",
          "00",
          "00",
        ].join(" ");
        console.log("发送 MPT 命令:", command);
        SendDataToHID(command);
      } else if (type === "mt") {
        const command = [
          "33",
          "05",
          "01",
          "00",
          row.toString(16).padStart(2, "0"),
          col.toString(16).padStart(2, "0"),
          "02",
          "00",
          "00",
          "00",
          "00",
          "00",
          "00",
        ].join(" ");
        console.log("发送 MT 命令:", command);
        SendDataToHID(command);
      } else if (type === "tgl") {
        const command = [
          "33",
          "05",
          "01",
          "00",
          row.toString(16).padStart(2, "0"),
          col.toString(16).padStart(2, "0"),
          "03",
          "00",
          "00",
          "00",
          "00",
          "00",
        ].join(" ");
        console.log("发送 TGL 命令:", command);
        SendDataToHID(command);
      } else if (type === "end") {
        const command = [
          "33",
          "05",
          "01",
          "00",
          row.toString(16).padStart(2, "0"),
          col.toString(16).padStart(2, "0"),
          "05",
          "00",
          "00",
        ].join(" ");
        console.log("发送 END 命令:", command);
        SendDataToHID(command);
      }

      console.log(
        "currentLayerMapArray",
        deletedKeyboard,
        currentLayerMapArray
      );

      // 如果有删除的按键，从 mappingData 中删除对应的映射
      if (deletedKeyboard && currentLayerMapArray) {
        Object.keys(currentLayerMapArray).forEach((key) => {
          // 对于 SOCD 类型，需要删除包含任一关联按键的映射
          if (type === "socd") {
            const socdKeys = deviceConfigures.superkeys.socdKeys.find(
              (k) => k.keyboard[0] === deletedKeyboard[0]
            );
            if (socdKeys && socdKeys.keys) {
              if (
                key.includes(socdKeys.keys[0]) ||
                key.includes(socdKeys.keys[1])
              ) {
                delete currentLayerMapArray[key];
              }
            }
          } else if (key.includes(deletedKeyboard[0])) {
            delete currentLayerMapArray[key];
          }
        });
        console.log("删除后的 currentLayerMapArray", currentLayerMapArray);

        // 清除选中状态
        deviceConfigures.mapPendingKey = [];
        // deviceConfigures.mapPendingSuperKey = [];
      }

      deviceConfigures.belowUpdateFlag = !deviceConfigures.belowUpdateFlag;
      deviceConfigures.superkeys = newSuperkeys;

      console.log("deviceConfigures", deviceConfigures.superkeys);

      return { ...state, [device]: deviceConfigures };
    }

    case "UPDATE_DSK_MODAL_DATA": {
      const { superKeyType, selectedKeys, super_key } = action.payload;
      console.log("editingIndex", super_key);
      const mapSuperKeys = super_key;
      console.log("mapSuperKeys", mapSuperKeys);
      const socdSuperKeys = [selectedKeys.keyboard, selectedKeys.socdkey];
      console.log("dsdssds", mapSuperKeys, superKeyType, selectedKeys);

      // 确保数组存在
      if (!deviceConfigures.superkeys) {
        deviceConfigures.superkeys = {
          mappingData: {},
          dskKeys: [],
          socdKeys: [],
          endKeys: [],
          tglKeys: [],
          mtKeys: [],
          mptKeys: [],
        };
      }

      // 创建新的数组
      let newSuperkeys = { ...deviceConfigures.superkeys };

      console.log("newSuperkeys", newSuperkeys);

      // 获取当前层的映射数据
      let currentLayerMapArray = getMappingData(
        deviceConfigures,
        deviceConfigures.topMenuIndex
      );

      // 更新 mappingData
      if (mapSuperKeys && mapSuperKeys.length > 0) {
        if (superKeyType === "socd") {
          currentLayerMapArray[socdSuperKeys[0]] = {
            showText: socdSuperKeys[0],
            type: superKeyType,
          };
          currentLayerMapArray[socdSuperKeys[1]] = {
            showText: socdSuperKeys[1],
            type: superKeyType,
          };
        } else {
          currentLayerMapArray[mapSuperKeys] = {
            showText: selectedKeys.keyboard[0],
            type: superKeyType, // 使用 superKeyType 而不是 selectedKeys.type
          };
        }
      }
      // 根据类型更新对应的数组
      if (superKeyType === "end") {
        const newEndKeys = [...(newSuperkeys.endKeys || [])];
        const existingIndex = (newSuperkeys.endKeys || []).findIndex(
          (item) => item.keyboard[0] === selectedKeys.keyboard[0]
        );
        if (existingIndex !== -1) {
          newEndKeys[existingIndex] = {
            keys: [selectedKeys.key1, selectedKeys.key2],
            originalKeys: [selectedKeys.key1, selectedKeys.originalKey],
            keyboard: selectedKeys.keyboard,
          };
        } else {
          newEndKeys.push({
            keys: [selectedKeys.key1, selectedKeys.key2],
            originalKeys: [selectedKeys.key1, selectedKeys.originalKey],
            keyboard: selectedKeys.keyboard,
          });
        }
        newSuperkeys = {
          ...newSuperkeys,
          endKeys: newEndKeys,
        };
        console.log("newEndKeysnewSuperkeys", newSuperkeys);
      } else if (superKeyType === "tgl") {
        const newTglKeys = [...(newSuperkeys.tglKeys || [])];
        const existingIndex = (newSuperkeys.tglKeys || []).findIndex(
          (item) => item.keyboard[0] === selectedKeys.keyboard[0]
        );
        if (existingIndex !== -1) {
          newTglKeys[existingIndex] = {
            keys: [selectedKeys.tglkey],
            originalKeys: [selectedKeys.originalTglkey],
            keyboard: selectedKeys.keyboard,
          };
        } else {
          newTglKeys.push({
            keys: [selectedKeys.tglkey],
            originalKeys: [selectedKeys.originalTglkey],
            keyboard: selectedKeys.keyboard,
          });
        }
        newSuperkeys = {
          ...newSuperkeys,
          tglKeys: newTglKeys,
        };
      } else if (superKeyType === "mt") {
        const newMtKeys = [...(newSuperkeys.mtKeys || [])];
        const existingIndex = (newSuperkeys.mtKeys || []).findIndex(
          (item) => item.keyboard[0] === selectedKeys.keyboard[0]
        );
        if (existingIndex !== -1) {
          newMtKeys[existingIndex] = {
            keys: [selectedKeys.mtkey1, selectedKeys.mtkey2],
            originalKeys: [
              selectedKeys.originalMtkey1,
              selectedKeys.originalMtkey2,
            ],
            sliderValue: selectedKeys.sliderValue,
            keyboard: selectedKeys.keyboard,
          };
        } else {
          newMtKeys.push({
            keys: [selectedKeys.mtkey1, selectedKeys.mtkey2],
            originalKeys: [
              selectedKeys.originalMtkey1,
              selectedKeys.originalMtkey2,
            ],
            sliderValue: selectedKeys.sliderValue,
            keyboard: selectedKeys.keyboard,
          });
        }
        newSuperkeys = {
          ...newSuperkeys,
          mtKeys: newMtKeys,
        };
      } else if (superKeyType === "mpt") {
        const newMptKeys = [...(newSuperkeys.mptKeys || [])];
        const existingIndex = (newSuperkeys.mptKeys || []).findIndex(
          (item) => item.keyboard[0] === selectedKeys.keyboard[0]
        );
        if (existingIndex !== -1) {
          newMptKeys[existingIndex] = {
            keys: [
              selectedKeys.mptkey1,
              selectedKeys.mptkey2,
              selectedKeys.mptkey3,
            ],
            originalKeys: [
              selectedKeys.originalMptkey1,
              selectedKeys.originalMptkey2,
              selectedKeys.originalMptkey3,
            ],
            mptDis: [
              selectedKeys.mptDis1,
              selectedKeys.mptDis2,
              selectedKeys.mptDis3,
            ],
            keyboard: selectedKeys.keyboard,
          };
        } else {
          newMptKeys.push({
            keys: [
              selectedKeys.mptkey1,
              selectedKeys.mptkey2,
              selectedKeys.mptkey3,
            ],
            originalKeys: [
              selectedKeys.originalMptkey1,
              selectedKeys.originalMptkey2,
              selectedKeys.originalMptkey3,
            ],
            mptDis: [
              selectedKeys.mptDis1,
              selectedKeys.mptDis2,
              selectedKeys.mptDis3,
            ],
            keyboard: selectedKeys.keyboard,
          });
        }

        newSuperkeys = {
          ...newSuperkeys,
          mptKeys: newMptKeys,
        };

        console.log("newMptKeysnewSuperkeys", newSuperkeys);
      } else if (superKeyType === "socd") {
        const existingSocdKeys = newSuperkeys.socdKeys || [];

        // 定义新的 key 对象
        const newKeyPair1 = {
          keys: [selectedKeys.socdkey, selectedKeys.keyboard],
          keyboard: selectedKeys.keyboard,
          isFastTriggerEnabled: selectedKeys.isFastTriggerEnabled,
          behaviorMode: selectedKeys.behaviorMode,
          socdTravelValue: selectedKeys.socdTravelValue,
        };

        const newKeyPair2 = {
          keys: [selectedKeys.keyboard, selectedKeys.socdkey],
          keyboard: selectedKeys.socdkey,
          isFastTriggerEnabled: selectedKeys.isFastTriggerEnabled,
          behaviorMode: selectedKeys.behaviorMode,
          socdTravelValue: selectedKeys.socdTravelValue,
        };

        // 遍历 existingSocdKeys，如果 keys 相同则替换，否则保留
        let updatedSocdKeys = existingSocdKeys.map((item) => {
          if (
            (item.keys[0] === newKeyPair1.keys[0] &&
              item.keys[1] === newKeyPair1.keys[1]) ||
            (item.keys[0] === newKeyPair2.keys[0] &&
              item.keys[1] === newKeyPair2.keys[1])
          ) {
            return item.keys[0] === newKeyPair1.keys[0]
              ? newKeyPair1
              : newKeyPair2;
          }
          return item;
        });

        // 如果原数组中没有相同的 keys，则新增
        const hasPair1 = existingSocdKeys.some(
          (item) =>
            item.keys[0] === newKeyPair1.keys[0] &&
            item.keys[1] === newKeyPair1.keys[1]
        );
        const hasPair2 = existingSocdKeys.some(
          (item) =>
            item.keys[0] === newKeyPair2.keys[0] &&
            item.keys[1] === newKeyPair2.keys[1]
        );

        if (!hasPair1) {
          updatedSocdKeys.push(newKeyPair1);
        }
        if (!hasPair2) {
          updatedSocdKeys.push(newKeyPair2);
        }

        // 更新 newSuperkeys
        newSuperkeys = {
          ...newSuperkeys,
          socdKeys: updatedSocdKeys,
        };

        console.log("newSocdKeys", updatedSocdKeys);
      } else if (superKeyType === "dsk") {
        const newDskKeys = [...(newSuperkeys.dskKeys || [])];

        // 查找是否存在相同的 keyboard 值
        const existingIndex = (newSuperkeys.dskKeys || []).findIndex(
          (item) => item.keyboard[0] === selectedKeys.keyboard[0]
        );

        if (existingIndex !== -1) {
          newDskKeys[existingIndex] = {
            keys: [
              selectedKeys.dskkey1,
              selectedKeys.dskkey2,
              selectedKeys.dskkey3,
              selectedKeys.dskkey4,
            ],
            originalKeys: [
              selectedKeys.originalDskkey1,
              selectedKeys.originalDskkey2,
              selectedKeys.originalDskkey3,
              selectedKeys.originalDskkey4,
            ],
            rowBinary: [
              selectedKeys.rowStates1,
              selectedKeys.rowStates2,
              selectedKeys.rowStates3,
              selectedKeys.rowStates4,
            ],
            keyboard: selectedKeys.keyboard,
          };
        } else {
          newDskKeys.push({
            keys: [
              selectedKeys.dskkey1,
              selectedKeys.dskkey2,
              selectedKeys.dskkey3,
              selectedKeys.dskkey4,
            ],
            originalKeys: [
              selectedKeys.originalDskkey1,
              selectedKeys.originalDskkey2,
              selectedKeys.originalDskkey3,
              selectedKeys.originalDskkey4,
            ],
            rowBinary: [
              selectedKeys.rowStates1,
              selectedKeys.rowStates2,
              selectedKeys.rowStates3,
              selectedKeys.rowStates4,
            ],
            keyboard: selectedKeys.keyboard,
          });
        }
        newSuperkeys = {
          ...newSuperkeys,
          dskKeys: newDskKeys,
        };
        console.log("aabjshdbjasdshdbjsh", newDskKeys);
      }

      for (const singleKey of mapSuperKeys) {
        const keys = singleKey.split(",");

        for (const key of keys) {
          if (key.length === 0) {
            continue;
          }
          console.log("key", key);

          // let keyObj = getMiddleKeyObjectById(key);
          let keyObj = getMiddleKeyObjectById(
            state.deviceParams.layoutMappingData,
            key
          );
          const row = keyObj.row;
          const col = keyObj.col;

          console.log("处理按键:", { row, col, superKeyType });

          // 根据不同类型发送不同的命令
          if (superKeyType === "mt") {
            const keyResult1 = getUidByText(selectedKeys.originalMtkey1);
            const keyResult2 = getUidByText(selectedKeys.originalMtkey2);
            console.log("keyResult", keyResult1, keyResult2);
            const command = [
              "33",
              "05",
              "01",
              "00",
              row.toString(16).padStart(2, "0"),
              col.toString(16).padStart(2, "0"),
              "02",
              keyResult2,
              keyResult1,

              // keyResult1.toString(16).padStart(4, "0").slice(0, 2) + " " + keyResult1.toString(16).padStart(4, "0").slice(2, 4),
              // keyResult2.toString(16).padStart(4, "0").slice(0, 2) + " " + keyResult2.toString(16).padStart(4, "0").slice(2, 4),
              selectedKeys.sliderValue
                .toString(16)
                .padStart(4, "0")
                .slice(0, 2) +
                " " +
                selectedKeys.sliderValue
                  .toString(16)
                  .padStart(4, "0")
                  .slice(2, 4),
            ].join(" ");
            console.log("发送 MT 命令:", command);
            SendDataToHID(command);
          } else if (superKeyType === "tgl") {
            const tglResult = getUidByText(selectedKeys.originalTglkey);
            const delay = 100;
            const command = [
              "33",
              "05",
              "01",
              "00",
              row.toString(16).padStart(2, "0"),
              col.toString(16).padStart(2, "0"),
              "03",
              "01",
              delay.toString(16).padStart(4, "0").slice(0, 2) +
                " " +
                delay.toString(16).padStart(4, "0").slice(2, 4),
              // tglResult.toString(16).padStart(4, "0").slice(0, 2) + " " + tglResult.toString(16).padStart(4, "0").slice(2, 4),
              tglResult,
            ].join(" ");
            console.log("发送 TGL 命令:", command);
            SendDataToHID(command);
          } else if (superKeyType === "mpt") {
            const mptResult1 = getUidByText(selectedKeys.originalMptkey1);
            const mptResult2 = getUidByText(selectedKeys.originalMptkey2);
            const mptResult3 = getUidByText(selectedKeys.originalMptkey3);
            console.log("mptResult", mptResult1, mptResult2, mptResult3);
            const command = [
              "33",
              "05",
              "01",
              "00",
              row.toString(16).padStart(2, "0"),
              col.toString(16).padStart(2, "0"),
              "04",
              Math.round(selectedKeys.mptDis1 * 100)
                .toString(16)
                .padStart(4, "0")
                .slice(0, 2) +
                " " +
                Math.round(selectedKeys.mptDis1 * 100)
                  .toString(16)
                  .padStart(4, "0")
                  .slice(2, 4),
              // mptResult1.toString(16).padStart(4, "0").slice(0, 2) + " " + mptResult1.toString(16).padStart(4, "0").slice(2, 4),
              mptResult1,
              Math.round(selectedKeys.mptDis2 * 100)
                .toString(16)
                .padStart(4, "0")
                .slice(0, 2) +
                " " +
                Math.round(selectedKeys.mptDis2 * 100)
                  .toString(16)
                  .padStart(4, "0")
                  .slice(2, 4),
              // mptResult2.toString(16).padStart(4, "0").slice(0, 2) + " " + mptResult2.toString(16).padStart(4, "0").slice(2, 4),
              mptResult2,
              Math.round(selectedKeys.mptDis3 * 100)
                .toString(16)
                .padStart(4, "0")
                .slice(0, 2) +
                " " +
                Math.round(selectedKeys.mptDis3 * 100)
                  .toString(16)
                  .padStart(4, "0")
                  .slice(2, 4),
              // mptResult3.toString(16).padStart(4, "0").slice(0, 2) + " " + mptResult3.toString(16).padStart(4, "0").slice(2, 4),
              mptResult3,
            ].join(" ");
            console.log("发送 MPT 命令:", command);
            SendDataToHID(command);
          } else if (superKeyType === "end") {
            const endResult = getUidByText(selectedKeys.originalKey);
            console.log("endResult", endResult);
            const command = [
              "33",
              "05",
              "01",
              "00",
              row.toString(16).padStart(2, "0"),
              col.toString(16).padStart(2, "0"),
              "05",
              endResult,
              // endResult.toString(16).padStart(4, "0").slice(0, 2) + " " + endResult.toString(16).padStart(4, "0").slice(2, 4)
            ].join(" ");
            console.log("发送 END 命令:", command);
            SendDataToHID(command);
          } else if (superKeyType === "socd") {
            const isKeyboard = key === selectedKeys.keyboard;
            const targetKey = isKeyboard
              ? selectedKeys.socdkey
              : selectedKeys.keyboard;
            // const targetKeyObj = getMiddleKeyObjectById(targetKey);
            let targetKeyObj = getMiddleKeyObjectById(
              state.deviceParams.layoutMappingData,
              targetKey
            );
            const targetKeyRow = targetKeyObj.row;
            const targetKeyCol = targetKeyObj.col;
            console.log("targetKeyObj", targetKeyObj);
            const command = [
              "33",
              "06",
              "01",
              "00",
              row.toString(16).padStart(2, "0"),
              col.toString(16).padStart(2, "0"),
              "06",
              "00",
              targetKeyRow.toString(16).padStart(2, "0"),
              targetKeyCol.toString(16).padStart(2, "0"),
              selectedKeys.behaviorMode.toString(16).padStart(2, "0"),
              selectedKeys.isFastTriggerEnabled ? "01" : "00",
            ].join(" ");
            console.log("发送 SOCD 命令:", command);
            SendDataToHID(command);

            const commandTravel1 = [
              "33",
              "03",
              "01",
              "00",
              row.toString(16).padStart(2, "0"),
              col.toString(16).padStart(2, "0"),
              Math.round(selectedKeys.socdTravelValue * 100)
                .toString(16)
                .padStart(4, "0")
                .slice(0, 2) +
                " " +
                Math.round(selectedKeys.socdTravelValue * 100)
                  .toString(16)
                  .padStart(4, "0")
                  .slice(2, 4),
              "00",
              Math.round(1.66 * 100)
                .toString(16)
                .padStart(4, "0")
                .slice(0, 2) +
                " " +
                Math.round(1.66 * 100)
                  .toString(16)
                  .padStart(4, "0")
                  .slice(2, 4),
              "00",
              Math.round(1.66 * 100)
                .toString(16)
                .padStart(4, "0")
                .slice(0, 2) +
                " " +
                Math.round(1.66 * 100)
                  .toString(16)
                  .padStart(4, "0")
                  .slice(2, 4),
            ].join(" ");
            SendDataToHID(commandTravel1);
            const commandTravel2 = [
              "33",
              "03",
              "01",
              "00",
              targetKeyRow.toString(16).padStart(2, "0"),
              targetKeyCol.toString(16).padStart(2, "0"),
              Math.round(selectedKeys.socdTravelValue * 100)
                .toString(16)
                .padStart(4, "0")
                .slice(0, 2) +
                " " +
                Math.round(selectedKeys.socdTravelValue * 100)
                  .toString(16)
                  .padStart(4, "0")
                  .slice(2, 4),
              "00",
              Math.round(1.66 * 100)
                .toString(16)
                .padStart(4, "0")
                .slice(0, 2) +
                " " +
                Math.round(1.66 * 100)
                  .toString(16)
                  .padStart(4, "0")
                  .slice(2, 4),
              "00",
              Math.round(1.66 * 100)
                .toString(16)
                .padStart(4, "0")
                .slice(0, 2) +
                " " +
                Math.round(1.66 * 100)
                  .toString(16)
                  .padStart(4, "0")
                  .slice(2, 4),
            ].join(" ");
            SendDataToHID(commandTravel2);
          } else if (superKeyType === "dsk") {
            const dskResult1 = selectedKeys.originalDskkey1
              ? getUidByText(selectedKeys.originalDskkey1)
              : "00 00";
            const dskResult2 = selectedKeys.originalDskkey2
              ? getUidByText(selectedKeys.originalDskkey2)
              : "00 00";
            const dskResult3 = selectedKeys.originalDskkey3
              ? getUidByText(selectedKeys.originalDskkey3)
              : "00 00";
            const dskResult4 = selectedKeys.originalDskkey4
              ? getUidByText(selectedKeys.originalDskkey4)
              : "00 00";
            console.log(
              "selectedKeys.rowStates1",
              dskResult1,
              dskResult2,
              dskResult3,
              dskResult4,
              selectedKeys.rowStates1,
              selectedKeys.rowStates2,
              selectedKeys.rowStates3,
              selectedKeys.rowStates4
            );
            const command = [
              "33",
              "05",
              "00",
              "00",
              row.toString(16).padStart(2, "0"),
              col.toString(16).padStart(2, "0"),
              "01",
              // 二进制
              selectedKeys.rowStates1 !== "00000000"
                ? parseInt(selectedKeys.rowStates1, 2)
                    .toString(16)
                    .toUpperCase()
                : "00",
              selectedKeys.rowStates2 !== "00000000"
                ? parseInt(selectedKeys.rowStates2, 2)
                    .toString(16)
                    .toUpperCase()
                : "00",
              selectedKeys.rowStates3 !== "00000000"
                ? parseInt(selectedKeys.rowStates3, 2)
                    .toString(16)
                    .toUpperCase()
                : "00",
              selectedKeys.rowStates4 !== "00000000"
                ? parseInt(selectedKeys.rowStates4, 2)
                    .toString(16)
                    .toUpperCase()
                : "00",
              "00",
              "00",
              "00",
              "00",
              dskResult1,
              dskResult2,
              dskResult3,
              dskResult4,
              // dskResult1 ? dskResult1.toString(16).padStart(4, "0").slice(0, 2) + " " + dskResult1.toString(16).padStart(4, "0").slice(2, 4) : '00',
              // dskResult2 ? dskResult2.toString(16).padStart(4, "0").slice(0, 2) + " " + dskResult2.toString(16).padStart(4, "0").slice(2, 4) : '00',
              // dskResult3 ? dskResult3.toString(16).padStart(4, "0").slice(0, 2) + " " + dskResult3.toString(16).padStart(4, "0").slice(2, 4) : '00',
              // dskResult4 ? dskResult4.toString(16).padStart(4, "0").slice(0, 2) + " " + dskResult4.toString(16).padStart(4, "0").slice(2, 4) : '00',
            ].join(" ");
            console.log("发送 DSK 命令:", command);
            SendDataToHID(command);
          }
        }
      }

      // 返回新的状态
      const newDeviceConfigures = {
        ...deviceConfigures,
        superkeys: newSuperkeys,
      };

      console.log("newDeviceConfigures", newDeviceConfigures);

      return {
        ...state,
        [device]: newDeviceConfigures,
      };
    }

    default:
      return state;
  }
}

function getMappingData(deviceConfigures, topMenuIndex) {
  if (topMenuIndex === 0) {
    let buttonMap2 = deviceConfigures.buttonMap;
    return buttonMap2.mappingData[buttonMap2.layerIndex];
  } else if (topMenuIndex === 1) {
    return deviceConfigures.lightness.mappingData;
  } else if (topMenuIndex === 2) {
    return deviceConfigures.keyValues;
  } else if (topMenuIndex === 3) {
    return deviceConfigures.superkeys.mappingData;
  } else if (topMenuIndex === 4) {
  }

  // 如果没有匹配的 topMenuIndex，返回 null
  return null;
}

function getConfiguredCommandForKeyMap(deviceConfigures, bottomTabIndex) {
  if (bottomTabIndex === 0) {
    //basic key map.
    let buttonMap2 = deviceConfigures.buttonMap;

    return buttonMap2.mappingData[buttonMap2.layerIndex];
  } else if (bottomTabIndex === 1) {
    // multimedia keys.
    return deviceConfigures.lightness.mappingData;
  } else if (bottomTabIndex === 2) {
    //lightness keys.
  } else if (bottomTabIndex === 3) {
    // compose keys.
  } else if (bottomTabIndex === 4) {
    // mouse keys.
  } else if (bottomTabIndex === 5) {
    // macro keys.
  }

  return null;
}

function getMiddleKeyObjectById(keys, id) {
  // 因为我们key值是[]数组，在多选的情况下会以","做join, 因此"<,"会被split.
  // 所以这里简单的打个补丁。
  if (id === "<") id = "<,";
  return keys.find((key) => key.id === id); // This will return the key object if found, or undefined if not found
}

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

const debouncedSaveData = debounce(SendDataToHID, 300);

function percentageToHexByte(percentage) {
  // Ensure the percentage is within the valid range
  if (percentage < 0 || percentage > 100) {
    throw new Error("Percentage must be between 0 and 100");
  }
  // Convert percentage to byte value
  const byteValue = Math.round((percentage / 100) * 255);
  // Convert byte value to hex string and pad with zero if necessary
  return byteValue.toString(16).padStart(2, "0").toUpperCase();
}

function hexStringToEnable(hexStr) {
  // 去掉空格，并转换为整数
  let num = parseInt(hexStr.replace(/\s+/g, ""), 16);

  // 进行按位与操作
  let result2 = num & 0x0fff;

  // 转回原来的格式
  let formattedHex = result2
    .toString(16)
    .padStart(4, "0")
    .toUpperCase()
    .match(/../g)
    .join(" ");

  return formattedHex;
}

function hexToHSV(hex) {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, "");

  // Parse the r, g, b values
  let r = parseInt(hex.substring(0, 2), 16) / 255;
  let g = parseInt(hex.substring(2, 4), 16) / 255;
  let b = parseInt(hex.substring(4, 6), 16) / 255;

  // Find the maximum and minimum values
  let max = Math.max(r, g, b);
  let min = Math.min(r, g, b);
  let h,
    s,
    v = max;

  let d = max - min;
  s = max === 0 ? 0 : d / max;

  if (max === min) {
    h = 0; // achromatic
  } else {
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return { h: h * 255, s: s * 255, v: v * 255 }; // Convert to degrees and percentage
}
export default commonReducer;
