import { useDispatch, useSelector } from "react-redux";
import CommonButton from "./CommonButton";
import {
  clearAllPopupAction,
  sendMacroReplaceAction,
  userSelectMacroToEditAction,
} from "../webhid/action";
import SeparatorLine from "./SeparatorLine";
import { useCallback, useEffect, useState, useRef } from "react";

import { v4 as uuid } from "uuid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

function MacroConfigureUI({}) {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  let common = useSelector((state) => state.persistent.common);

  let isLight = common.isLight;
  const device = common.deviceName + "-Configure";
  common = common[device];

  const defaultMacros = common.macroMap.defaultMacros;
  const leftMacroSelectIndex = common.macroMap.selectIndex;

  const [actionSelectIndex, setActionSelectIndex] = useState(-1);
  //const [pairedSelectIndex, setPairedSelectIndex] = useState(-1);

  const [recording, setRecording] = useState(false);

  const [editKeyName, setEditKeyName] = useState(false);

  const [recordActions, setRecordActions] = useState(
    leftMacroSelectIndex >= 0
      ? defaultMacros[leftMacroSelectIndex].actionList
      : []
  );

  const recordButtonRef = useRef(null);

  function isDataPaired() {
    for (let i = 0; i < recordActions.length; i++) {
      let paired = calcPairedSelectIndex(i);
      if (paired === -1) {
        console.log("Get mismatched data", i, recordActions[i]);
        return false;
      }
    }

    return true;
  }

  function calcPairedSelectIndex(selectIndex) {
    console.log("We try to find paired index for " + selectIndex);
    let isDown = recordActions[selectIndex].isDown;
    let pairedIndex = -1;
    if (!isDown) {
      for (let i = selectIndex - 1; i >= 0; i--) {
        if (
          recordActions[i].isDown &&
          recordActions[i].name === recordActions[selectIndex].name
        ) {
          pairedIndex = i;
          break;
        }
      }
    } else {
      for (let i = selectIndex + 1; i < recordActions.length; i++) {
        if (
          recordActions[i].isDown === false &&
          recordActions[i].name === recordActions[selectIndex].name
        ) {
          pairedIndex = i;
          break;
        }
      }
    }

    return pairedIndex;
  }

  const editEventFunc = useCallback(
    (event) => {
      if (!event instanceof KeyboardEvent) {
        return;
      }

      let eventDesc =
        /*t("type")*/ "Key" +
        "-" +
        (event.key === " " ? event.code : event.key);

      setRecordActions((prev) => {
        let newActions = JSON.parse(JSON.stringify(prev));
        if (actionSelectIndex >= 0) {
          newActions[actionSelectIndex].name = eventDesc;

          let pariedIndex = calcPairedSelectIndex(actionSelectIndex);
          newActions[pariedIndex].name = eventDesc;
        }
        window.removeEventListener("mouseup", editEventFunc);
        window.removeEventListener("mousedown", editEventFunc);
        window.removeEventListener("keydown", editEventFunc);
        window.removeEventListener("keyup", editEventFunc);
        window.removeEventListener("contextmenu", editEventFunc);
        setEditKeyName(false);

        return newActions;
      });
    },
    [recordActions, actionSelectIndex, editKeyName]
  );

  const recordEventFunc = useCallback((event) => {
    if (event.type == "contextmenu") {
      return;
    }

    if (event instanceof KeyboardEvent) {
      console.log("We get key press", event.code);
    } else {
      return;
    }

    let target = event.target;

    // 向上寻找，直到找到包含 `data-type="parent"` 的元素
    while (target && !target.dataset.type) {
      target = target.parentElement;
    }

    if (target && target.dataset.type === "eventTarget-Record") {
      return;
    }

    let eventDesc = "Key" + "-" + (event.key === " " ? event.code : event.key);
    let isMouse = false;
    let isDown = event.type === "keydown";

    setRecordActions((prev) => {
      console.log("my length is", prev.length);
      if (prev.length > 50) {
        return prev;
      }

      let duration = 0;
      if (prev.length > 0) {
        duration = event.timeStamp - prev[prev.length - 1].timeStamp;
      }

      let delay = Math.floor(duration); //.toFixed(2);
      const action = {
        isMouse: isMouse,
        name: eventDesc,
        key: uuid(),
        isDown: isDown,
        code: event.code,
        duration: isDown ? 0 : delay,
        // extra, no display.
        timeStamp: event.timeStamp,
      };

      return [...prev, action];
    });
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", keyPressMonitor);
    return () => {
      window.removeEventListener("keydown", keyPressMonitor);
    };
  }, []);

  const keyPressMonitor = useCallback((event) => {
    if (event instanceof KeyboardEvent) {
      if (event.code === "Escape") {
        dispatch(clearAllPopupAction());
      }
    }
  });

  function onActionButtonClicked(action) {
    if (leftMacroSelectIndex === -1) {
      return;
    }

    if (recording && action !== "record") {
      // when we recording ignore all other clicks.
      return;
    }

    console.log("action " + action + " is clicked");
    if (action === "record") {
      setActionSelectIndex(-1);
      setRecording((prev) => {
        const recording = !prev;
        console.log("Recording state is now:", recording);
        if (recording) {
          window.addEventListener("mouseup", recordEventFunc);
          window.addEventListener("mousedown", recordEventFunc);
          window.addEventListener("keydown", recordEventFunc);
          window.addEventListener("keyup", recordEventFunc);
          window.addEventListener("contextmenu", recordEventFunc);
        } else {
          //   setLastRecordLength(recordActions.length);
          window.removeEventListener("mouseup", recordEventFunc);
          window.removeEventListener("mousedown", recordEventFunc);
          window.removeEventListener("keydown", recordEventFunc);
          window.removeEventListener("keyup", recordEventFunc);
          window.removeEventListener("contextmenu", recordEventFunc);
        }
        return recording;
      });
    } else if (action === "insert") {
    } else if (action === "save") {
      if (leftMacroSelectIndex === -1) {
        return;
      }

      if (!isDataPaired()) {
        toast.warning(t("taost_unpaired_action"), {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        return;
      }

      const newMacro = JSON.parse(
        JSON.stringify(defaultMacros[leftMacroSelectIndex])
      );

      let macro = {
        ...newMacro,
        actionList: recordActions,
      };

      dispatch(sendMacroReplaceAction(leftMacroSelectIndex, macro));

      toast.info(t("toast_save_success"), {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (action === "reset") {
      if (leftMacroSelectIndex === -1) {
        return;
      }

      setRecordActions([]);
      setActionSelectIndex(-1);
      const newMacro = JSON.parse(
        JSON.stringify(defaultMacros[leftMacroSelectIndex])
      );

      let macro = {
        ...newMacro,
        actionList: [],
      };
      dispatch(sendMacroReplaceAction(leftMacroSelectIndex, macro));
    } else if (action === "trim") {
    }
  }

  return (
    <div
      className="container.ui"
      style={{
        position: "absolute",
        width: "76%",
        height: "80%",
        top: "50%",
        padding: 0,
        left: "50%",
        backgroundColor: isLight ? "white" : "#2B2B2B",
        borderRadius: "8px",
        zIndex: 909,
        transform: "translate(-50%, -50%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          margin: "2vh",
          position: "relative",
        }}
      >
        <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
          {t("macro_setting")}
        </span>

        <div
          className="buttonGroup"
          style={{
            borderRadius: "5px",
            padding: "0.6vh 1.9vh",
            position: "absolute",
            right: 0,
          }}
        >
          <CommonButton
            text={t("dialog_btn_cancel")}
            actionCallback={() => {
              dispatch(clearAllPopupAction());
            }}
            blue={false}
          />

          <CommonButton
            text={t("dialog_btn_confirm")}
            actionCallback={() => {
              dispatch(clearAllPopupAction());
            }}
            blue={true}
          />
        </div>
      </div>
      <SeparatorLine />

      <div
        className="main_parts"
        style={{
          display: "flex",
          flex: 1,
          height: "90%",
          width: "100%",
          fontSize: "0.4rem",
          padding: 0,
          margin: 0,
        }}
      >
        <div
          className="leftpanel"
          style={{
            display: "flex",
            flex: 1,
            height: "100%",
            flexDirection: "column",

            padding: "0 2vh",
            margin: "0",
          }}
        >
          <span
            style={{
              fontSize: "0.8rem",
              marginTop: "1.1vh",
              fontWeight: "bold",
            }}
          >
            {t("macro_function")}
          </span>
          <div
            className="scrollsection"
            style={{
              overflowY: "auto",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {defaultMacros.map((item, index) => {
              return (
                <span
                  key={item.name}
                  style={{
                    width: "100%",
                    fontSize: "0.7rem",
                    cursor: "pointer",
                    marginTop: "0.5vh",
                    padding: "1.7vh 0vh 1.7vh 1vh",
                    borderRadius: "5px",
                    backgroundColor:
                      leftMacroSelectIndex === index
                        ? "#0042da"
                        : isLight
                        ? "white"
                        : "#2b2b2b",
                  }}
                  onClick={() => {
                    // setLeftMacroSelectIndex(index);
                    dispatch(userSelectMacroToEditAction(index));
                    setActionSelectIndex(-1);
                    setRecordActions(defaultMacros[index].actionList);
                  }}
                >
                  {item.name}
                </span>
              );
            })}
          </div>
        </div>
        <div
          className="middlepanel"
          style={{
            display: "flex",
            flex: 1,
            height: "100%",
            flexDirection: "column",
            padding: "0 2vh",

            borderLeft: "1px solid #d8d8d8",
            borderRight: "1px solid #d8d8d8",
          }}
        >
          <div
            style={{
              display: "flex",
              height: "5%",
              width: "100%",
              margin: "1.0vh 0",

              justifyContent: "space-between",
            }}
          >
            <div>
              <button
                ref={recordButtonRef}
                style={{
                  border: "none",
                  borderRadius: "5px",
                  backgroundColor: "#d9d9d9",
                  margin: "0.3vh 0.8vh",
                  padding: "0.6vh 1.9vh",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                data-type="eventTarget-Record"
                onClick={() => {
                  console.log("We begin to record.");
                  onActionButtonClicked("record");
                  recordButtonRef.current.blur();
                }}
              >
                <img
                  src={recording ? "/stop.png" : "/record.png"}
                  style={{
                    width: "1.8vh",
                    marginRight: "0.3vh",
                    height: "1.8vh",
                  }}
                ></img>
                <span>
                  {" "}
                  {recording ? t("macro_stop_record") : t("macro_start_record")}
                </span>
              </button>
            </div>

            <div>
              <CommonButton
                text={t("macro_clear_all")}
                actionCallback={() => {
                  onActionButtonClicked("reset");
                }}
                blue={false}
              />
              <CommonButton
                text={t("macro_save")}
                actionCallback={() => {
                  onActionButtonClicked("save");
                }}
                blue={true}
              />
            </div>
          </div>

          <div
            className="scrollsection"
            style={{
              display: "flex",
              height: "100%",
              maxHeight: "100%",
              margin: "0 0 0 0",
              width: "100%",
              margin: "1.1vh 0",
              padding: "0vh 0",
              flexDirection: "column",
              overflowY: "auto",
              //border: "1px solid red",
            }}
          >
            {recordActions.map((item, index) => (
              <MacroAction
                isDown={item.isDown}
                event={item.name}
                key={item.key}
                duration={item.duration}
                selected={actionSelectIndex === index}
                onClick={(action) => {
                  if (action === "select") {
                    setActionSelectIndex(index);
                  } else if (action === "trash") {
                    console.log("We get trash action");

                    let pariedIndex = calcPairedSelectIndex(index);
                    //newActions[pariedIndex].name = eventDesc;
                    setActionSelectIndex(-1);

                    let minValue = index > pariedIndex ? pariedIndex : index;
                    let maxValue = index > pariedIndex ? index : pariedIndex;

                    setRecordActions((prev) => {
                      return [
                        ...prev.slice(0, minValue),
                        ...prev.slice(minValue + 1, maxValue),
                        ...prev.slice(maxValue + 1),
                      ];
                    });
                  }
                }}
              /> //timeStamp
            ))}
          </div>
        </div>
        <div
          className="rightpanel"
          style={{
            display: "flex",
            flex: 1,
            height: "100%",
            flexDirection: "column",
            padding: "0 2vh",
            margin: "0",
          }}
        >
          <span
            style={{
              fontSize: "0.8rem",
              fontWeight: "bold",
              marginTop: "1.1vh",
            }}
          >
            {t("macro_setting")}
          </span>

          <span
            style={{
              fontSize: "0.8rem",
              fontWeight: "bold",
              marginTop: "1.1vh",
            }}
          >
            {t("macro_key_content")}
          </span>

          <div
            style={{
              width: "3.7vh",
              height: "3.7vh",
              borderRadius: "5px",
              border: "1px solid #dfdfdf",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0.2vh 5vh",
              margin: "0.5vh 0",
              color: isLight ? "black" : "#333",
              backgroundColor: editKeyName ? "#0042DA" : "#FFFFFF",
              fontSize: "0.7rem",
            }}
            onClick={() => {
              setEditKeyName((prev) => {
                const editKeyName = !prev;

                if (editKeyName) {
                  window.addEventListener("keydown", editEventFunc);
                  window.addEventListener("keyup", editEventFunc);
                } else {
                  window.removeEventListener("keydown", editEventFunc);
                  window.removeEventListener("keyup", editEventFunc);
                }
                return editKeyName;
              });
            }}
          >
            {actionSelectIndex >= 0
              ? recordActions[actionSelectIndex].name
              : ""}
          </div>

          <span
            style={{
              fontSize: "0.8rem",
              fontWeight: "bold",
              marginTop: "1.1vh",
            }}
          >
            {t("macro_key_direction")}
          </span>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor:
                actionSelectIndex >= 0 &&
                recordActions[actionSelectIndex].isDown
                  ? "#0042DA"
                  : "#d9d9d9",
              borderRadius: "5px",
              margin: "1.6vh 0 0 0",
              padding: "0",
              alignItems: "center",
            }}
            onClick={() => {
              setRecordActions((prev) => {
                if (actionSelectIndex >= 0) {
                  let newActions = JSON.parse(JSON.stringify(prev));

                  newActions[actionSelectIndex].isDown = true;
                  return newActions;
                } else {
                  return prev;
                }
              });
            }}
          >
            <img
              src={"/down-arrow.png"}
              style={{
                width: "5%",
                padding: "1.1vh 0",
                margin: "0",
              }}
            ></img>
            <button
              style={{
                border: "none",
                borderRadius: "5px",
                backgroundColor: "transparent",
                // margin: "0.3vh 1.8vh 0.3vh 0",
                // padding: "0.6vh 1.9vh",
                cursor: "pointer",
                width: `${t("macro_key_release").length}ch`, // Set width based on character count
              }}
            >
              {t("macro_key_press")}
            </button>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#d9d9d9",
              backgroundColor:
                actionSelectIndex >= 0 &&
                recordActions[actionSelectIndex].isDown
                  ? "#d9d9d9"
                  : actionSelectIndex >= 0
                  ? "#0042DA"
                  : "#d9d9d9",
              borderRadius: "5px",
              margin: "1.6vh 0 0 0",
              padding: "0",
              alignItems: "center",
            }}
            onClick={() => {
              setRecordActions((prev) => {
                if (actionSelectIndex >= 0) {
                  let newActions = JSON.parse(JSON.stringify(prev));

                  newActions[actionSelectIndex].isDown = false;
                  return newActions;
                } else {
                  return prev;
                }
              });
            }}
          >
            <img
              src={"/up-arrow.png"}
              style={{
                width: "5%",
                padding: "1.1vh 0",
                margin: "0",
              }}
            ></img>

            <button
              style={{
                border: "none",
                borderRadius: "5px",
                backgroundColor: "transparent",
                // margin: "0.3vh 1.8vh 0.3vh 0",
                // padding: "0.6vh 1.9vh",
                cursor: "pointer",
                width: `${t("macro_key_release").length}ch`,
              }}
            >
              {t("macro_key_release")}
            </button>
          </div>

          <span
            style={{
              fontSize: "0.8rem",
              fontWeight: "bold",
              marginTop: "1.1vh",
            }}
          >
            {t("macro_key_time") + "(ms)"}
          </span>

          <input
            style={{
              marginTop: "1vh",
              height: "1.8rem",
              backgroundColor: "#d9d9d9",
              borderRadius: "5px",
              border: "none",
              padding: "0vh  1.2vh",
              outline: "none",
            }}
            min={0}
            step={1}
            type="number"
            value={
              actionSelectIndex >= 0
                ? recordActions[actionSelectIndex].duration
                : ""
            }
            onChange={(e) => {
              if (actionSelectIndex >= 0 && actionSelectIndex >= 0) {
                setRecordActions((prev) => {
                  let newActions = JSON.parse(JSON.stringify(prev));
                  newActions[actionSelectIndex].duration = parseInt(
                    e.target.value
                  );
                  return newActions;
                });
              }
            }}
          ></input>
        </div>
      </div>
    </div>
  );
}

export default MacroConfigureUI;
function MacroAction({ duration, event, isDown, selected, onClick }) {
  const timeDuration = duration + "ms";

  const common = useSelector((state) => state.persistent.common);
  let isLight = common.isLight;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: selected ? "#0042da" : isLight ? "#f2f4f5" : "#4b4b4b",
        color: isLight ? "black" : "white",
        borderRadius: "5px",
        width: "100%",
        height: "4.2vh",
        margin: "0 0 1.0vh 0",
        fontSize: "0.8rem",
        justifyContent: "space-between",
        padding: 0,
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={() => {
        onClick("select");
      }}
    >
      <img
        src="/draggable.png"
        style={{
          width: "9%",
          backgroundColor: "#D5dde5",
          margin: "0.9vh 0",
          height: "100%",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
        }}
      ></img>
      <img
        src={isDown ? "/down-arrow.png" : "/up-arrow.png"}
        style={{ width: "6%", padding: "0.6vh" }}
      ></img>

      <div
        style={{
          //   width: "6.7vh",
          height: "3.7vh",
          borderRadius: "5px",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: isLight ? "#FFFFFF" : "#999",
        }}
      >
        <span style={{ padding: "0 2px" }}>{event}</span>
      </div>

      <span
        style={{
          backgroundColor: "#D9E2ee",
          borderRadius: "3px",
          padding: "0.5vh 1vh",
          width: "10vh",
        }}
      >
        {timeDuration}
      </span>

      <img
        src="/broom.png"
        style={{ width: "7%", marginRight: "1vh" }}
        onClick={(e) => {
          e.stopPropagation();
          onClick("trash");
        }}
      ></img>
    </div>
  );
}
