import { useState, useEffect, useRef, useCallback } from "react";
import TabNavigation from "./components/TabNavigation";
import "./FastTriggerPage.css";
import imgUrl from "./icons/image@1x.png";
import { useDispatch, useSelector } from "react-redux";
import {
  setFastTriggerTravelValue,
  setFastTriggerRTEnabled,
  setFastTriggerSensitivity,
  setFastTriggerIndependentSensitivity,
  setFastTriggerTriggerSensitivity,
  setFastTriggerResetSensitivity,
  disableKeyReportingAction,
  startCalibrationAction,
  endCalibrationAction,
  userClickMapPendingKeyAction
} from "./webhid/action";
import { useTranslation } from "react-i18next";

// 添加防抖函数
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

function FastTriggerPage({}) {
  const [t, i18n] = useTranslation();
  const tabNavData = [t("actuation_distance"), t("switch_calibration")];
  const [activeTab, setActiveTab] = useState(0);

  const dispatch = useDispatch();
  let common = useSelector((state) => state.persistent.common);
  const device = common.deviceName + "-Configure";
  const deviceConfigures = common[device];
  console.log("deviceConfigures", deviceConfigures);
  let isLight = common.isLight;

  const [travelValue, setTravelValue] = useState(1.66);
  const [isRTEnabled, setIsRTEnabled] = useState(false);
  const [sensitivity, setSensitivity] = useState(1.66);
  const [isIndependentSensitivity, setIsIndependentSensitivity] =
    useState(false);
  const [triggerSensitivity, setTriggerSensitivity] = useState(1.66);
  const [resetSensitivity, setResetSensitivity] = useState(1.66);

  // 创建防抖的更新函数
  const debouncedUpdateKeyValues = useCallback(
    debounce((selectedKeyIds, newValue) => {
      selectedKeyIds.forEach((keyId) => {
        dispatch({
          type: "UPDATE_KEY_VALUES",
          payload: {
            keyId: keyId,
            values: newValue,
          },
        });
      });
    }, 300),
    []
  );

  // 创建防抖的更新travelValue函数
  const debouncedUpdateTravelValue = useCallback(
    debounce((selectedKeyIds, newValue) => {
      selectedKeyIds.forEach((keyId) => {
        dispatch({
          type: "UPDATE_KEY_VALUES",
          payload: {
            keyId: keyId,
            values: { travelValue: newValue },
          },
        });
      });
    }, 300),
    []
  );

  // Handle tab switching
  const handleTabChange = (index) => {
    setActiveTab(index);
    // Clear key selection when switching tabs
    dispatch(userClickMapPendingKeyAction([]));
    // Clear calibration logs when switching to SetTriggerKey

    if (index === 0) {
      dispatch({
        type: "SHOW_KEY_LOG",
        payload: { keyLogs: [] },
      });
      setTravelValue(1.66);
      setSensitivity(1.66);
      setIsIndependentSensitivity(false);
      setTriggerSensitivity(1.66);
      setResetSensitivity(1.66);
    }
  };

  // Update keyValues when any value changes
  useEffect(() => {
    const selectedKeyIds = deviceConfigures.mapPendingKey;
    if (selectedKeyIds.length > 0) {
      const newValue = {
        isRTEnabled,
        sensitivity,
        isIndependentSensitivity,
        triggerSensitivity,
        resetSensitivity,
      };
      debouncedUpdateKeyValues(selectedKeyIds, newValue);
    }
  }, [
    sensitivity,
    isIndependentSensitivity,
    triggerSensitivity,
    resetSensitivity,
  ]);

  useEffect(() => {
    const selectedKeyIds = deviceConfigures.mapPendingKey;
    if (selectedKeyIds.length > 0) {
      debouncedUpdateTravelValue(selectedKeyIds, travelValue);
    }
  }, [travelValue]);

  return (
    <div
      style={{
        display: "flex",
        margin: 0,
        flexDirection: "column",
        fontSize: "1rem",
        alignItems: "center",
        padding: "1vh 1vh",
        backgroundColor: isLight ? "white" : "#252525",
        color: isLight ? "black" : "white",
        borderRadius: "8px",
        justifyContent: "flex-start",
        marginTop: "3vh",
        height: "80vh",
      }}
    >
      <TabNavigation
        data={tabNavData}
        onClickFunc={handleTabChange}
        activeTab={activeTab}
      />
      {activeTab === 0 && (
        <SetTriggerKey
          travelValue={travelValue}
          setTravelValue={setTravelValue}
          isRTEnabled={isRTEnabled}
          setIsRTEnabled={setIsRTEnabled}
          sensitivity={sensitivity}
          setSensitivity={setSensitivity}
          isIndependentSensitivity={isIndependentSensitivity}
          setIsIndependentSensitivity={setIsIndependentSensitivity}
          triggerSensitivity={triggerSensitivity}
          setTriggerSensitivity={setTriggerSensitivity}
          resetSensitivity={resetSensitivity}
          setResetSensitivity={setResetSensitivity}
        />
      )}
      {activeTab === 1 && <AxisCalibration type="axisCalibration" />}
    </div>
  );
}

function SetTriggerKey({
  travelValue,
  setTravelValue,
  isRTEnabled,
  setIsRTEnabled,
  sensitivity,
  setSensitivity,
  isIndependentSensitivity,
  setIsIndependentSensitivity,
  triggerSensitivity,
  setTriggerSensitivity,
  resetSensitivity,
  setResetSensitivity,
}) {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();
  let common = useSelector((state) => state.persistent.common);
  let isLight = common.isLight;

  const [originalSensitivity, setOriginalSensitivity] = useState(sensitivity);

  useEffect(() => {
    dispatch(userClickMapPendingKeyAction([]));
  }, []);

  return (
    <div style={{ display: "flex", width: "100%", height: "100%" }}>
      <div
        className="leftpart"
        style={{
          height: "100%",
          width: "40%",
          display: "flex",
          // paddingRight: "1vh",
          flexDirection: "column",
          borderRight: isLight ? "1px #ddd solid" : "1px #525252 solid",
          marginRight: "4vh",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            width: "60%",
            marginLeft: "35%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <span
            style={{
              marginTop: "1vh",
              marginLeft: "1vh",
              fontWeight: "bold",
              fontSize: "15px",
            }}
          >
            {t("actuation_distance")}
          </span>
          <span
            style={{
              margin: "1vh",
              fontSize: "12px",
              whiteSpace: "pre-wrap",
              color: isLight ? "#525252" : "#636363",
              lineHeight: "1.5",
            }}
          >
            {t("actuation_distance_desc")}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {/* 图片 */}
            <img
              src={imgUrl}
              alt=""
              style={{
                width: "90px",
                height: "90px",
                marginRight: "3vh",
              }}
            />

            {/* 竖向滑块 + 数值显示 */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input
                type="range"
                min="0.10"
                max="3.50"
                step="0.01"
                defaultValue="1.66"
                value={travelValue}
                onChange={(e) => {
                  const newValue = parseFloat(e.target.value);
                  setTravelValue(newValue);
                  dispatch(setFastTriggerTravelValue(newValue));
                }}
                style={{
                  writingMode: "vertical-lr", // 竖向滑块
                  width: "3px",
                  height: "100px",
                  marginBottom: "1vh",
                  accentColor: "#4e6dd0", // 进度条颜色
                }}
              />
              {/* 显示当前数值 */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "14px",
                  marginTop: "0.5vh",
                  padding: "3px 8px",
                  borderRadius: "5px",
                  background: isLight ? "#f5f5f5" : "#2f2f2f",
                  border: isLight ? "1px solid #ddd" : "1px solid #2f2f2f",
                }}
              >
                <button
                  style={{
                    background: isLight ? "#ddd" : "#666666",
                    border: "none",
                    padding: "3px 6px",
                    marginRight: "5px",
                    cursor: "pointer",
                    borderRadius: "3px",
                    color: isLight ? "#525252" : "white",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    setTravelValue(Math.max(0.1, travelValue - 0.01));
                    dispatch(
                      setFastTriggerTravelValue(
                        Math.max(0.1, travelValue - 0.01)
                      )
                    );
                  }}
                >
                  -
                </button>
                <span>{travelValue.toFixed(2)}mm</span>
                <button
                  style={{
                    background: isLight ? "#ddd" : "#666666",
                    border: "none",
                    padding: "3px 6px",
                    marginLeft: "5px",
                    cursor: "pointer",
                    borderRadius: "3px",
                    color: isLight ? "#525252" : "white",
                  }}
                  onClick={() => {
                    setTravelValue(Math.min(3.5, travelValue + 0.01));
                    dispatch(
                      setFastTriggerTravelValue(
                        Math.min(3.5, travelValue + 0.01)
                      )
                    );
                  }}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="rightpart"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "60%",
          height: "100%",
          margin: "0.2vh",
          position: "relative",
        }}
      >
        <div
          style={{
            width: "80%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          {/* 文字说明 */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              paddingRight: "3vh",
            }}
          >
            <span
              style={{
                marginTop: "1vh",
                marginLeft: "1vh",
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              {t("rapid_trigger_mode")}
            </span>
            <label className="switch">
              <input
                type="checkbox"
                checked={isRTEnabled}
                disabled={isIndependentSensitivity}
                onChange={() => {
                  const RTEnabled = !isRTEnabled;
                  console.log("asda", RTEnabled);
                  setIsRTEnabled(RTEnabled);
                  dispatch(setFastTriggerRTEnabled(RTEnabled));
                }}
              />
              <span className="slider"></span>
            </label>
          </div>
          <span
            style={{
              margin: "1vh",
              fontSize: "13px",
              whiteSpace: "pre-wrap",
              color: isLight ? "#525252" : "#636363",
              lineHeight: "1.5",
            }}
          >
            {t("rapid_trigger_mode_desc")}
          </span>

          {/* 只有当RT模式开启时，才显示灵敏度设置 */}

          {/* {isRTEnabled && ( */}
          <>
            {/* 灵敏度滑块 + 数值 + 按钮 */}
            {!isIndependentSensitivity && (
              <div style={{ width: "100%" }}>
                <span
                  style={{
                    marginTop: "1vh",
                    marginLeft: "1vh",
                    fontSize: "13px",
                  }}
                >
                  {t("sensitivity")}
                </span>
                <div
                  style={{
                    marginTop: "1vh",
                    marginLeft: "1vh",
                    color: "#b5b5b5",
                    width: "50%",
                    fontSize: "12px",
                  }}
                >
                  {t("sensitivity_desc")}
                </div>
                {/* 滑块上方的 "低" 和 "高" */}
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "5px",
                    marginLeft: "1vh",
                    marginTop: "1vh",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "25px", // 控制正方形大小
                      height: "25px",
                      backgroundColor: isLight ? "#f5f5f5" : "#2f2f2f", // 灰色背景
                      borderRadius: "4px", // 可调整圆角
                      fontSize: "12px",
                    }}
                  >
                    <span>{t("low")}</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "25px", // 控制正方形大小
                      height: "25px",
                      backgroundColor: isLight ? "#f5f5f5" : "#2f2f2f", // 灰色背景
                      borderRadius: "4px", // 可调整圆角
                      fontSize: "12px",
                    }}
                  >
                    <span>{t("high")}</span>
                  </div>
                </div>

                {/* 滑块、数值显示、恢复默认 在同一行 */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    marginLeft: "1vh",
                  }}
                >
                  {/* 滑块 */}
                  <input
                    type="range"
                    min="0.10"
                    max="2.50"
                    step="0.01"
                    value={sensitivity}
                    onChange={(e) => {
                      const newValue = parseFloat(e.target.value);
                      setSensitivity(newValue);
                      dispatch(setFastTriggerSensitivity(newValue));
                      setOriginalSensitivity(newValue);
                    }}
                    style={{
                      width: "50%",
                      marginRight: "10px",
                      accentColor: "#4e6dd0",
                      height: "3px",
                    }}
                    // disabled={isIndependentSensitivity}
                    disabled={!isRTEnabled}
                  />

                  {/* 数值 + 按钮 */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <button
                      onClick={() => {
                        setSensitivity(Math.max(0.1, sensitivity - 0.01));
                        dispatch(
                          setFastTriggerSensitivity(
                            Math.max(0.1, sensitivity - 0.01)
                          )
                        );
                      }}
                      style={{
                        padding: "3px 6px",
                        cursor: "pointer",
                        borderRadius: "3px",
                        border: isLight
                          ? "1px solid #ccc"
                          : "1px solid #525252",
                        color: isLight ? "#525252" : "white",
                        background: isLight ? "#f5f5f5" : "#2f2f2f",
                      }}
                      disabled={!isRTEnabled}
                    >
                      -
                    </button>
                    <span
                      style={{
                        fontSize: "12px",
                        background: isLight ? "#f5f5f5" : "#2f2f2f",
                        padding: "3px 8px",
                        borderRadius: "5px",
                        border: isLight
                          ? "1px solid #ddd"
                          : "1px solid #525252",
                        color: isLight ? "#525252" : "white",
                      }}
                    >
                      {sensitivity.toFixed(2)}mm
                    </span>
                    <button
                      onClick={() => {
                        console.log("sensi", Math.min(2.5, sensitivity + 0.01));
                        setSensitivity(Math.min(2.5, sensitivity + 0.01));
                        dispatch(
                          setFastTriggerSensitivity(
                            Math.min(2.5, sensitivity + 0.01)
                          )
                        );
                      }}
                      style={{
                        padding: "3px 6px",
                        cursor: "pointer",
                        borderRadius: "3px",
                        border: isLight
                          ? "1px solid #ccc"
                          : "1px solid #525252",
                        color: isLight ? "#525252" : "white",
                        background: isLight ? "#f5f5f5" : "#2f2f2f",
                      }}
                      disabled={!isRTEnabled}
                    >
                      +
                    </button>
                  </div>

                  {/* 恢复默认按钮 */}
                  <button
                    onClick={() => {
                      dispatch(setFastTriggerSensitivity(1.66));
                      setSensitivity(1.66);
                    }}
                    style={{
                      marginLeft: "10px",
                      padding: "5px 10px",
                      background: isLight ? "#f5f5f5" : "#2f2f2f",
                      border: "none",
                      cursor: "pointer",
                      borderRadius: "5px",
                      fontSize: "12px",
                      color: isLight ? "#525252" : "white",
                    }}
                    disabled={isIndependentSensitivity}
                  >
                    {t("restore_defaults")}
                  </button>
                </div>
              </div>
            )}

            {/* 独立的上收/下击灵敏度开关 */}
            <div style={{ marginTop: "1vh", marginLeft: "1vh", width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <span style={{ fontSize: "13px", fontWeight: "bold" }}>
                  {t("independent_up/down")}
                </span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={isIndependentSensitivity}
                    onChange={() => {
                      const newValue = !isIndependentSensitivity;
                      setIsIndependentSensitivity(newValue);
                      dispatch(setFastTriggerIndependentSensitivity(newValue));
                      if (newValue) {
                        console.log("111", newValue);
                        setTriggerSensitivity(originalSensitivity);
                        setResetSensitivity(originalSensitivity);
                      } else {
                        console.log("222", newValue);
                        setSensitivity(originalSensitivity);
                      }
                    }}
                    disabled={!isRTEnabled}
                  />
                  <span className="slider"></span>
                </label>
              </div>
              <span
                style={{
                  marginTop: "0.5vh",
                  fontSize: "13px",
                  color: isLight ? "#525252" : "#636363",
                  lineHeight: "1.5",
                }}
              >
                {t("actuation_reset")}
              </span>
            </div>

            {isIndependentSensitivity && (
              <>
                {/* 灵敏度滑块 + 数值 + 按钮 */}
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "5px",
                      marginLeft: "1vh",
                      marginTop: "1vh",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12px",
                        color: isLight ? "#525252" : "white",
                      }}
                    >
                      {t("press_actuation")}
                    </span>
                  </div>

                  {/* 滑块、数值显示、恢复默认 在同一行 */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      marginLeft: "1vh",
                    }}
                  >
                    {/* 滑块 */}
                    <input
                      type="range"
                      min="0.10"
                      max="2.50"
                      step="0.01"
                      value={triggerSensitivity}
                      onChange={(e) => {
                        setTriggerSensitivity(parseFloat(e.target.value));
                        dispatch(
                          setFastTriggerTriggerSensitivity(
                            parseFloat(e.target.value)
                          )
                        );
                      }}
                      style={{
                        width: "50%",
                        marginRight: "10px",
                        accentColor: "#4e6dd0",
                        height: "3px",
                      }}
                    />

                    {/* 数值 + 按钮 */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <button
                        onClick={() => {
                          setTriggerSensitivity(
                            Math.max(0.1, triggerSensitivity - 0.01)
                          );
                          dispatch(
                            setFastTriggerTriggerSensitivity(
                              Math.max(0.1, triggerSensitivity - 0.01)
                            )
                          );
                        }}
                        style={{
                          padding: "3px 6px",
                          cursor: "pointer",
                          borderRadius: "3px",
                          border: isLight
                            ? "1px solid #ccc"
                            : "1px solid #525252",
                          color: isLight ? "#525252" : "white",
                          background: isLight ? "#f5f5f5" : "#2f2f2f",
                        }}
                      >
                        -
                      </button>
                      <span
                        style={{
                          fontSize: "12px",
                          background: isLight ? "#f5f5f5" : "#2f2f2f",
                          padding: "3px 8px",
                          borderRadius: "5px",
                          border: isLight
                            ? "1px solid #ddd"
                            : "1px solid #525252",
                          color: isLight ? "#525252" : "white",
                        }}
                      >
                        {triggerSensitivity.toFixed(2)}mm
                      </span>
                      <button
                        onClick={() => {
                          setTriggerSensitivity(
                            Math.min(2.5, triggerSensitivity + 0.01)
                          );
                          dispatch(
                            setFastTriggerTriggerSensitivity(
                              Math.min(2.5, triggerSensitivity + 0.01)
                            )
                          );
                        }}
                        style={{
                          padding: "3px 6px",
                          cursor: "pointer",
                          borderRadius: "3px",
                          border: isLight
                            ? "1px solid #ccc"
                            : "1px solid #525252",
                          color: isLight ? "#525252" : "white",
                          background: isLight ? "#f5f5f5" : "#2f2f2f",
                        }}
                      >
                        +
                      </button>
                    </div>

                    {/* 恢复默认按钮 */}
                    <button
                      onClick={() => {
                        setTriggerSensitivity(1.66);
                        dispatch(setFastTriggerTriggerSensitivity(1.66));
                      }}
                      style={{
                        marginLeft: "10px",
                        padding: "5px 10px",
                        background: isLight ? "#f5f5f5" : "#2f2f2f",
                        border: "none",
                        cursor: "pointer",
                        borderRadius: "5px",
                        fontSize: "12px",
                        color: isLight ? "#525252" : "white",
                      }}
                    >
                      {t("restore_defaults")}
                    </button>
                  </div>

                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "5px",
                      marginLeft: "1vh",
                      marginTop: "1vh",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12px",
                        color: isLight ? "#525252" : "white",
                      }}
                    >
                      {t("release_reset")}
                    </span>
                  </div>

                  {/* 滑块、数值显示、恢复默认 在同一行 */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      marginLeft: "1vh",
                    }}
                  >
                    {/* 滑块 */}
                    <input
                      type="range"
                      min="0.10"
                      max="2.50"
                      step="0.01"
                      value={resetSensitivity}
                      onChange={(e) => {
                        setResetSensitivity(parseFloat(e.target.value));
                        dispatch(
                          setFastTriggerResetSensitivity(
                            parseFloat(e.target.value)
                          )
                        );
                      }}
                      style={{
                        width: "50%",
                        marginRight: "10px",
                        accentColor: "#4e6dd0",
                        height: "3px",
                      }}
                    />

                    {/* 数值 + 按钮 */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <button
                        onClick={() => {
                          setResetSensitivity(
                            Math.max(0.1, resetSensitivity - 0.01)
                          );
                          dispatch(
                            setFastTriggerResetSensitivity(
                              Math.max(0.1, resetSensitivity - 0.01)
                            )
                          );
                        }}
                        style={{
                          padding: "3px 6px",
                          cursor: "pointer",
                          borderRadius: "3px",
                          border: isLight
                            ? "1px solid #ccc"
                            : "1px solid #525252",
                          color: isLight ? "#525252" : "white",
                          background: isLight ? "#f5f5f5" : "#2f2f2f",
                        }}
                      >
                        -
                      </button>
                      <span
                        style={{
                          fontSize: "12px",
                          background: isLight ? "#f5f5f5" : "#2f2f2f",
                          padding: "3px 8px",
                          borderRadius: "5px",
                          border: isLight
                            ? "1px solid #ddd"
                            : "1px solid #525252",
                          color: isLight ? "#525252" : "white",
                        }}
                      >
                        {resetSensitivity.toFixed(2)}mm
                      </span>
                      <button
                        onClick={() => {
                          setResetSensitivity(
                            Math.min(2.5, resetSensitivity + 0.01)
                          );
                          dispatch(
                            setFastTriggerResetSensitivity(
                              Math.min(2.5, resetSensitivity + 0.01)
                            )
                          );
                        }}
                        style={{
                          padding: "3px 6px",
                          cursor: "pointer",
                          borderRadius: "3px",
                          border: isLight
                            ? "1px solid #ccc"
                            : "1px solid #525252",
                          color: isLight ? "#525252" : "white",
                          background: isLight ? "#f5f5f5" : "#2f2f2f",
                        }}
                      >
                        +
                      </button>
                    </div>

                    {/* 恢复默认按钮 */}
                    <button
                      onClick={() => {
                        setResetSensitivity(1.66);
                        dispatch(setFastTriggerResetSensitivity(1.66));
                      }}
                      style={{
                        marginLeft: "10px",
                        padding: "5px 10px",
                        background: isLight ? "#f5f5f5" : "#2f2f2f",
                        border: "none",
                        cursor: "pointer",
                        borderRadius: "5px",
                        fontSize: "12px",
                        color: isLight ? "#525252" : "white",
                      }}
                    >
                      {t("restore_defaults")}
                    </button>
                  </div>
                </div>
              </>
            )}
          </>
          {/* )} */}
        </div>
        {/* <style>
        {`
          
        `}
      </style> */}
      </div>
    </div>
  );
}

function AxisCalibration({ type }) {
  const [isACEnabled, setIsACEnabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isStartACEnabled, setIsStartACEnabled] = useState(false);
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();
  let common = useSelector((state) => state.persistent.common);
  const keyboardData = common.deviceParams.layoutMappingData;

  const [isListening, setIsListening] = useState(false);
  const [keyLogsCurrent, setKeyLogsCurrent] = useState(new Set()); // 用 useRef 存储最新的 keyLogs

  const keyMappings = {
    Escape: "Esc",
    F1: "F1",
    F2: "F2",
    F3: "F3",
    F4: "F4",
    F5: "F5",
    F6: "F6",
    F7: "F7",
    F8: "F8",
    F9: "F9",
    F10: "F10",
    F11: "F11",
    F12: "F12",
    "": "LOCK",
    PrintScreen: "Prtscr",
    ScrollLock: "ScrLk",
    Pause: "Prtblk",
    Backquote: "~",
    Digit1: "!1",
    Digit2: "@2",
    Digit3: "#3",
    Digit4: "$4",
    Digit5: "%5",
    Digit6: "^6",
    Digit7: "&7",
    Digit8: "*8",
    Digit9: "(9",
    Digit0: ")0",
    Minus: "_-",
    Equal: "+=",
    Backspace: "Backspace",
    Insert: "Insert",
    Home: "Home",
    PageUp: "PgUp",
    Tab: "Tab",
    KeyQ: "Q",
    KeyW: "W",
    KeyE: "E",
    KeyR: "R",
    KeyT: "T",
    KeyY: "Y",
    KeyU: "U",
    KeyI: "I",
    KeyO: "O",
    KeyP: "P",
    BracketLeft: "{[",
    BracketRight: "}]",
    Backslash: "|\\",
    Delete: "Del",
    End: "End",
    PageDown: "PgDn",
    CapsLock: "Caps Lock",
    KeyA: "A",
    KeyS: "S",
    KeyD: "D",
    KeyF: "F",
    KeyG: "G",
    KeyH: "H",
    KeyJ: "J",
    KeyK: "K",
    KeyL: "L",
    Semicolon: ":;",
    Quote: "\"'",
    Enter: "Enter",
    ShiftLeft: "ShiftL",
    KeyZ: "Z",
    KeyX: "X",
    KeyC: "C",
    KeyV: "V",
    KeyB: "B",
    KeyN: "N",
    KeyM: "M",
    Comma: "<,",
    Period: ">.",
    Slash: "?/",
    ShiftRight: "ShiftR",
    ArrowUp: "Arrowup",
    ControlLeft: "CtrlL",
    MetaLeft: "WinL",
    AltLeft: "AltL",
    Space: "Spacebar",
    AltRight: "AltR",
    MetaRight: "WinR",
    ContextMenu: "Fn",
    ControlRight: "CtrlR",
    ArrowLeft: "ArrowL",
    ArrowDown: "ArrowD",
    ArrowRight: "ArrowR",
  };

  const keyLogsRef = useRef([]);

  useEffect(() => {
    if (!isListening) return;

    const handleKeyDown = (event) => {
      event.preventDefault();
      const keyPressed = keyMappings[event.code] || event.code;
      const matchedKey = keyboardData.find(
        (key) => key.id.toLowerCase() === keyPressed.toLowerCase()
      );

      if (matchedKey && !keyLogsRef.current.includes(matchedKey.id)) {
        keyLogsRef.current = [...keyLogsRef.current, matchedKey.id];
        setKeyLogsCurrent([...keyLogsRef.current]);
      }

      dispatch({
        type: "SHOW_KEY_LOG",
        payload: {
          keyLogs: keyLogsRef.current,
        },
      });
    };

    const handleKeyUp = (event) => {
      console.log("asdasda", event);
      if (
        event.key === "PrintScreen" &&
        !keyLogsRef.current.includes("Prtscr")
      ) {
        keyLogsRef.current = [...keyLogsRef.current, "Prtscr"];
        setKeyLogsCurrent([...keyLogsRef.current]);

        dispatch({
          type: "SHOW_KEY_LOG",
          payload: { keyLogs: keyLogsRef.current },
        });
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [isListening]);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "45%",
          height: "100%",
          margin: "0.2vh",
        }}
      >
        {/* 文字说明 */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            paddingRight: "3vh",
          }}
        >
          <span
            style={{
              marginTop: "1vh",
              marginLeft: "1vh",
              fontWeight: "bold",
              fontSize: "15px",
            }}
          >
            {t("switch_calibration")}
          </span>
          {/* <label className="switch">
            <input type="checkbox" checked={isACEnabled} onChange={() => setIsACEnabled(!isACEnabled)} />
            <span className="slider"></span>
          </label> */}
        </div>
        <span
          style={{
            margin: "1vh",
            fontSize: "13px",
            whiteSpace: "pre-wrap",
            color: "#525252",
            lineHeight: "1.5",
          }}
        >
          {t("switch_calibration_desc")}
        </span>

        {/* {isACEnabled && ( */}
        <>
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "5vh",
            }}
          >
            <button
              style={{
                marginLeft: "10px",
                padding: "5px 10px",
                background: "#153ddf",
                border: "none",
                cursor: "pointer",
                borderRadius: "5px",
                fontSize: "14px",
                color: "#f1f7ff",
                width: "22vh",
                height: "4vh",
              }}
              onClick={() => {
                setIsModalOpen(true);
                // dispatch(disableKeyReportingAction());  // 禁用按键上报
              }}
            >
              {t("start_calibration")}
            </button>
          </div>
        </>
        {/* )} */}
      </div>
      {/* 弹窗 */}
      {isModalOpen && (
        <>
          {/* 遮罩层 */}
          <div
            className="modal-overlay"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 999,
              pointerEvents: "none",
            }}
            // onClick={() => setIsModalOpen(false)}
          ></div>

          {/* 弹窗 */}
          <div
            className="modal-content"
            style={{
              position: "fixed",
              top: "63%",
              left: "41%",
              // transform: "translate(-50%, -50%)",
              background: "white",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
              zIndex: 1000,
              width: "70vh",
              height: "22vh",
              pointerEvents: "auto",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                paddingRight: "3vh",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  marginBottom: "1vh",
                }}
              >
                {t("switch_calibration")}
              </div>
              {/* <label className="switch">
                <input type="checkbox" checked={isStartACEnabled} onChange={() => setIsStartACEnabled(!isStartACEnabled)} />
                <span className="slider"></span>
              </label> */}
            </div>

            {/* <span 
              style={{
                fontSize: "13px",
                whiteSpace: "pre-wrap",
                color: "#525252",
                lineHeight: "1.5",
              }}
            >
              开启开关后将会实时上报行程数据，可能会导致设备性能下降，请校准完毕后关闭开关。
            </span> */}

            {/* {isStartACEnabled && ( */}
            <>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "2vh",
                }}
              >
                {!isStartACEnabled ? (
                  <button
                    style={{
                      marginLeft: "10px",
                      background: "#4468ff",
                      border: "none",
                      cursor: "pointer",
                      borderRadius: "5px",
                      fontSize: "14px",
                      color: "#f1f7ff",
                      width: "25vh",
                      height: "4vh",
                    }}
                    onClick={() => {
                      setIsStartACEnabled(true);
                      setIsListening(true);
                      dispatch(startCalibrationAction());
                      // setIsModalOpen(false);
                    }}
                  >
                    {t("start_calibration")}
                  </button>
                ) : (
                  <button
                    style={{
                      marginLeft: "10px",
                      background: "#4468ff",
                      border: "none",
                      cursor: "pointer",
                      borderRadius: "5px",
                      fontSize: "14px",
                      color: "#f1f7ff",
                      width: "25vh",
                      height: "4vh",
                    }}
                    onClick={() => {
                      setIsStartACEnabled(false);
                      setIsModalOpen(false);
                      dispatch(endCalibrationAction());
                      dispatch({
                        type: "SHOW_KEY_LOG",
                        payload: {
                          keyLogs: [],
                        },
                      });
                      dispatch(userClickMapPendingKeyAction([]));
                    }}
                  >
                    {t("save_calibration")}
                  </button>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "2vh",
                }}
              >
                <button
                  style={{
                    marginLeft: "10px",
                    background: "#4468ff",
                    border: "none",
                    cursor: "pointer",
                    borderRadius: "5px",
                    fontSize: "12px",
                    color: "#f1f7ff",
                    width: "33vh",
                    height: "8vh",
                    whiteSpace: "pre-wrap",
                    lineHeight: "1.5",
                    textAlign: "left",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  {t("friendly_reminder")}
                </button>
              </div>
            </>
            {/* )} */}
          </div>
        </>
      )}
    </div>
  );
}

export default FastTriggerPage;
