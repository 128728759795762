import handleInputReport from "./hidresponse.js";

import { CONNECTED_DEVICE, SET_FATAL_LOG, CHANGE_APP_LANG } from "./constants";

// DEBUG
export const sendFatalLogAction = (logInfo) => ({
  type: SET_FATAL_LOG,
  payload: logInfo,
});

// SYSTEM
export const changeAppLanguage = (lang) => ({
  type: CHANGE_APP_LANG,
  payload: lang,
});

// CONNECTION
/* Connect Async action */
export const connectDeviceAction = (venders, products) => {
  return async (dispatch) => {
    if (!navigator.hid) {
      console.error(
        "Your browser does not support HID, please use chrome based browser."
      );
      dispatch(sendFatalLogAction("Your browser is not supported"));
      dispatch(connectedDeviceAction(false, null));
      return;
    }

    const generatedArray = venders.map((vendor, index) => ({
      usagePage: 0xff60,
      usage: 0x61,
      vendorId: vendor,
      productId: products[index],
    }));

    console.log("we filter device by", generatedArray);
    const devices = await navigator.hid.requestDevice({
      filters: generatedArray,
    });

    if (devices.length === 0) {
      console.log("No device selected.");
      dispatch(sendFatalLogAction("No avaiable devices."));
      return;
    }

    console.warn("We current get devices", devices);
    let device = devices[0];

    try {
      await device.close();
    } catch (e) {}

    console.log("we connec device", device, "current number", devices.length);
    try {
      await device.open();
      dispatch(connectedDeviceAction(true, device.productName));
      dispatch(
        userAddNewDevice(device.productName, device.vendorId, device.productId)
      );
      dispatch(sendFatalLogAction("Connect success."));
      document.title = device.productName;
    } catch (e) {
      console.log("Open hid devices failed with error", e);
      dispatch(sendFatalLogAction("Open hid devices failed with error" + e));
    }

    console.log("We add event handler here for device", device);
    device.addEventListener("inputreport", handleInputReport);
  };
};

export const connectedDeviceAction = (success, productName) => ({
  type: CONNECTED_DEVICE,
  payload: success,
  productName: productName,
});

export const restoreAllConfigsAction = () => ({
  type: "RESTORE_ALL_CONFIGS",
});

///////////////////////////New added.//////////////////////////
export const userClickTopMenuAction = (index) => ({
  type: "USER_CLICK_TOP_MENU_ACTION",
  payload: index,
});

export const userClickLayerAction = (index) => ({
  type: "USER_CLICK_LAYER_ACTION",
  payload: index,
});

export const userSetDragMappingAction = (cooridnate, keyId) => ({
  type: "USER_SET_DRAG_MAPPING_ACTION",
  payload: keyId,
  cooridnate: cooridnate,
});

// ROCK.
export const userClickMapPendingKeyAction = (keysArray) => ({
  type: "USER_CLICK_MAP_PENDING_KEY",
  payload: keysArray,
  //cooridnate: cooridnate,
});

export const userClickBottomTabAction = (index) => ({
  type: "USER_CLICK_BOTTOM_TAB_ACTION",
  payload: index,
});

export const useCombinedResultAction = (content) => ({
  type: "USER_COMBINED_RESULT_ACTION",
  payload: content,
});

export const userStartDragAction = (text, uid) => ({
  type: "USER_CLICK_START_DRAG_ACTION",
  payload: text,
  uid: uid,
});

export const userStartRealDragAction = () => ({
  type: "USER_CLICK_START_REAL_DRAG_ACTION",
});

export const userMouseDownCoordinate = (x, y) => ({
  type: "USER_CLICK_DOWN_COORDINATE",
  x: x,
  y: y,
});

export const userStopDragAction = () => ({
  type: "USER_CLICK_STOP_DRAG_ACTION",
});

export const userMouseUpPosAction = (x, y) => ({
  type: "USER_CLICK_MOUSE_UP_POS",
  x: x,
  y: y,
});

export const userClickKeyboardBelowAction = (content, uid, combine = null) => ({
  type: "USER_CLICK_KEYBOARD_BELOW",
  payload: content,
  uid: uid,
  combine: combine,
});

export const userClickLightnessAction = (payload) => ({
  type: "USER_CLICK_LIGHTNESS_ACTION",
  payload: payload,
});

export const userResetToFactoryAction = () => ({
  type: "USER_CLICK_RESET_TO_FACTORY",
});

export const userCheckUpdateAction = () => ({
  type: "USER_CLICK_CHECK_UPDATE",
});

export const clearAllPopupAction = () => ({
  type: "CLEAR_ALL_POPUP",
});

export const clearButtonMapConfigures = () => ({
  type: "CLEAR_ALL_BUTTON_MAP",
});

export const userSaveProductList = (productList) => ({
  type: "USER_SAVE_PRODUCT_LIST",
  payload: productList,
});

export const userAddNewDevice = (name, vid, pid) => ({
  type: "USER_ADD_NEW_DEVICE",
  name: name,
  vid: vid,
  pid: pid,
});

export const userUpdateLayoutData = (obj) => ({
  type: "USER_UPDATE_LAYOUT_DATA",
  payload: obj,
});

export const fillMacroBufferSizeAction = (hexString) => ({
  type: "FILL_MACRO_BUFFER_SIZE",
  payload: hexString,
});

export const userSelectMacroToEditAction = (index) => ({
  type: "SELECT_MACRO_ITEM",
  payload: index,
});

export const settingSetGameDisableList = (list) => ({
  type: "SETTING_SET_GAME_DISABLE_LIST",
  payload: list,
});

export const settingSetRadioChoice = (type, index) => ({
  type: "SETTING_SET_RADIO_CHOICE",
  name: type,
  payload: index,
});

export const updateWholeConfAction = (tab) => ({
  type: "BUTTON_MAP_UPDATE_CONFIG",
  payload: tab,
});

export const sendAlertHintsAction = (text, source) => ({
  type: "SEND_ALERT_HINTS",
  payload: text,
  source: source,
});

export const popupConfigureUIAction = (show, index) => ({
  type: "POPUP_CONFIGURE_UI",
  payload: show,
  index: index,
});

export const blurMainPageAction = (blurpage) => ({
  type: "BLUR_MAIN_PAGE",
  payload: blurpage,
});

export const userImportWholeConfig = (configJson, orderId) => ({
  type: "USER_IMPORT_WHOLE_CONFIG",
  payload: configJson,
  index: orderId,
});

export const connectButtonToMacroRelationship = (map) => ({
  type: "CONNECT_BUTTON_TO_MACRO_RELATION",
  payload: map,
});

export const updateActiveTabAction = (tabIndex) => ({
  type: "BUTTON_MAP_UPDATE_TAB_INDEX",
  payload: tabIndex,
});

// UI-> Settings
export const fillFirmwareVersion = (version) => ({
  type: "FILL_FIRMWARE_VERSION",
  payload: version,
});

export const fillBatteryInformationAction = (byte) => ({
  type: "FILL_BATTERY_INFO",
  payload: byte,
});

// UI -> Macro
export const sendMacroOptionSelectedAction = (index, checked) => ({
  type: "MACRO_OPTION_CLICKED",
  index: index,
  checked: checked,
});

export const sendMacroAddedAction = (action) => ({
  type: "MACRO_ADDED",
  payload: action,
});

export const sendMacroDeletedAction = (index) => ({
  type: "MACRO_DELETED",
  index: index,
});

export const sendMacroReplaceAction = (index, macro) => ({
  type: "MACRO_REPLACED",
  index: index,
  payload: macro,
});

export const setFastTriggerTravelValue = (value) => ({
  type: "SET_FAST_TRIGGER_TRAVEL_VALUE",
  payload: value,
});

export const setFastTriggerRTEnabled = (value) => ({
  type: "SET_FAST_TRIGGER_RT_ENABLED",
  payload: value,
});

export const setFastTriggerSensitivity = (value) => ({
  type: "SET_FAST_TRIGGER_SENSITIVITY",
  payload: value,
});

export const setFastTriggerIndependentSensitivity = (value) => ({
  type: "SET_FAST_TRIGGER_INDEPENDENT_SENSITIVITY",
  payload: value,
});

export const setFastTriggerTriggerSensitivity = (value) => ({
  type: "SET_FAST_TRIGGER_TRIGGER_SENSITIVITY",
  payload: value,
});

export const setFastTriggerResetSensitivity = (value) => ({
  type: "SET_FAST_TRIGGER_RESET_SENSITIVITY",
  payload: value,
});

export const sendFastTriggerSettingsToHID = (settings) => ({
  type: "SEND_FAST_TRIGGER_SETTINGS_TO_HID",
  payload: settings,
});

export const fastTriggerInformation = (value) => ({
  type: "FAST_TRIGGER_INFORMATION",
  payload: value,
});

export const startCalibrationAction = () => ({
  type: "START_CALIBRATION",
});

export const endCalibrationAction = () => ({
  type: "END_CALIBRATION",
});

export const disableKeyReportingAction = () => ({
  type: "DISABLE_KEY_REPORTING",
});

export const updateDSKModalData = (superKeyType, selectedKeys, super_key) => ({
  type: "UPDATE_DSK_MODAL_DATA",
  payload: { superKeyType, selectedKeys, super_key },
});

////////////////////////////////////////////////////////////////////

export function getShortProductName(longName) {}
