import { StateStore } from "./statestore.js";
import {
  fillFirmwareVersion,
  fillBatteryInformationAction,
  fastTriggerInformation,
  fillMacroBufferSizeAction,
} from "./action.js";

function handleInputReport(event) {
  let dataArray = Array.from(new Uint8Array(event.data.buffer));
  const hexArray = dataArray.map((num) => num.toString(16).padStart(2, "0")); // Convert to hex

  console.log("Query: We received some event", hexArray, event);

  if (hexArray[0] === "02" && hexArray[1] === "04") {
    // Step 2: Parse version from subsequent bytes

    const major = parseInt(hexArray[4], 10); // Third byte as patch version
    const minor = parseInt(hexArray[5], 10); // Third byte as patch version

    // Combine them into a version string
    const version = `${major}.${minor}`;
    console.log("Query: Firmware version " + version);
    StateStore.dispatch(fillFirmwareVersion(version));
  } else if (hexArray[0] === "02" && hexArray[1] === "26") {
    console.log("Query: Battery info is", hexArray[2]);
    StateStore.dispatch(fillBatteryInformationAction(hexArray[2]));
  } else if (hexArray[0] === "02" && hexArray[1] === "13") {
    //SN, No need.
  } else if (hexArray[0].toUpperCase() === "0D") {
    // Macro buffer size.
    console.log("Query: Macro buffer is", hexArray[1], hexArray[2]);
    StateStore.dispatch(
      fillMacroBufferSizeAction(hexArray[1] + " " + hexArray[2])
    );
  } else if (hexArray[0] === "34" && hexArray[1] === "03") {
    StateStore.dispatch(fastTriggerInformation(hexArray));
  }
}

export default handleInputReport;
